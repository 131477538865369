import React, { ReactNode } from "react";

import styled from "@emotion/native";
import { TouchableWithoutFeedback } from "react-native";
import colors from "../constants/colors";
import { BlurView } from "expo-blur";

const Container = styled.View`
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const ContainerBlur = styled(BlurView)`
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const Content = styled.View`
  display: flex;
  flex-direction: column;
  background-color: ${colors.surfaceSecondary};
  padding: 24px;
  border-radius: 20px;
  width: 100%;
`;

type Props = {
  children: ReactNode;
  isVisible: boolean;
  onClose?: () => void;
  blur?: boolean;
};

export default function Modal({ children, isVisible, onClose, blur }: Props): JSX.Element {
  if (!isVisible) return <></>;

  return (
    <TouchableWithoutFeedback onPress={onClose}>
      {blur ? (
        <ContainerBlur intensity={blur ? 60 : 0} tint={"dark"}>
          <TouchableWithoutFeedback onPress={(e) => e.stopPropagation()}>
            <Content>{children}</Content>
          </TouchableWithoutFeedback>
        </ContainerBlur>
      ) : (
        <Container>
          <TouchableWithoutFeedback onPress={(e) => e.stopPropagation()}>
            <Content>{children}</Content>
          </TouchableWithoutFeedback>
        </Container>
      )}
    </TouchableWithoutFeedback>
  );
}
