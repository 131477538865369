import React, { useState } from "react";
import styled from "@emotion/native";
import InputText from "../../../ui/InputText";
import { useTranslation } from "react-i18next";
import { getGameId } from "../../../services/api/games";
import Button from "../../../ui/Button";
import Modal from "../../../ui/Modal";
import fonts from "../../../ui/constants/fonts";
import colors from "../../../ui/constants/colors";

const Content = styled.View`
  gap: 12px;
  padding-bottom: 12px;
`;

const Title = styled.Text`
  font-size: 20px;
  font-family: ${fonts.extraBold};
  text-transform: uppercase;
  color: ${colors.textBody};
`;

const InfoTxt = styled.Text`
  position: absolute;
  font-size: 16px;
  font-family: ${fonts.regular};
  color: ${colors.textBody};
  bottom: -15px;
  right: 7px;
`;

type Props = {
  isVisible: boolean;
  onGameFound: (gameId: string) => void;
  onClose: () => void;
};

export default function JoinGameModal({ isVisible, onGameFound, onClose }: Props) {
  const { t } = useTranslation();
  const [joinNumber, setJoinNumber] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      setError("");
      setLoading(true);
      setJoinNumber("");
      const gameId = await getGameId({ joinNumber });
      if (gameId) onGameFound(gameId);
      else setError(t("Invalid game number"));
    } catch (e) {
      setError(t("Invalid game number"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Content>
        <Title>{t("Enter a Game Number")}</Title>
        <InputText
          maxLength={7}
          numeric
          value={joinNumber}
          placeholder="012678"
          testID="join-game-input"
          onValueChange={(value) => {
            setJoinNumber(value.replace(/\s/, ""));
          }}
        />
        <Button
          testID="join-game-modal-button"
          primary
          text="Submit"
          onPress={() => submit()}
          disabled={joinNumber == ""}
        />
        <InfoTxt>{loading ? t("Searching your game...") : error}</InfoTxt>
      </Content>
    </Modal>
  );
}
