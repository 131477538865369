import styled from "@emotion/native";
import React from "react";
import { ImageSourcePropType } from "react-native";

const Container = styled.TouchableOpacity<{ size: number; color: string }>`
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  border-radius: ${({ size }) => `${size / 2}px`};
  background-color: ${({ color }) => color};
  justify-content: center;
  align-items: center;
`;

const AvatarImage = styled.Image<{ size: number }>`
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  border-radius: ${({ size }) => `${size / 2}px`};
`;

type Props = {
  size?: number;
  color: string;
  avatarId: number;
  onPress?: () => void;
};

const Avatars: { [key: number]: ImageSourcePropType } = {
  1: require("./avatars/1.png"),
  2: require("./avatars/2.png"),
  3: require("./avatars/3.png"),
  4: require("./avatars/4.png"),
  5: require("./avatars/5.png"),
  6: require("./avatars/6.png"),
  7: require("./avatars/7.png"),
  8: require("./avatars/8.png"),
  9: require("./avatars/9.png"),
  10: require("./avatars/10.png"),
};

export function Avatar({ size = 60, color, avatarId, onPress }: Props) {
  return (
    <Container onPress={onPress} size={size} color={color} disabled={!onPress}>
      <AvatarImage source={Avatars[avatarId]} size={size} />
    </Container>
  );
}
