import React, { useMemo } from "react";
import styled from "@emotion/native";
import colors from "../../../../ui/constants/colors";
import fonts from "../../../../ui/constants/fonts";
import { Suspect } from "../../../../services";
import { Avatar } from "../../../../components/Avatar";
import { use } from "i18next";
import { useTranslation } from "react-i18next";

const Container = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Message = styled.View<{ isSuccess: boolean }>`
  padding: 12px;
  border-radius: 8px;
  background-color: ${(props) => (props.isSuccess ? colors.statusSuccess : colors.statusError)};
  gap: 4px;
  flex: 1;
`;

const Name = styled.Text`
  font-size: 16px;
  color: ${colors.textBody};
  font-family: ${fonts.bold};
`;

const TextContent = styled.Text`
  font-size: 16px;
  color: ${colors.textBody};
  font-family: ${fonts.regular};
`;

type Props = {
  text: string;
  isSuccess: boolean;
  suspect: Suspect | null;
  isYou: boolean;
};

export function ResultResponse({ text, isSuccess, suspect, isYou }: Props) {
  const { t } = useTranslation();

  return (
    <Container>
      {suspect && (
        <Avatar size={60} color={colors.team[suspect.teamNumber]} avatarId={suspect.avatarId} />
      )}
      <Message isSuccess={isSuccess}>
        <Name>
          {suspect?.name} {isYou ? t("(YOU)") : ""}
        </Name>
        <TextContent>{text}</TextContent>
      </Message>
    </Container>
  );
}
