import environments from "../../environments";
import { User } from "../types";
import { customFetch } from "./utils/customFetch";

export const getMe = async (token: string): Promise<User | null> => {
  return await customFetch(`${environments.apiUrl}/users/me`, {
    headers: {
      UserToken: token,
    },
  });
};

export const createAccount = async (token: string): Promise<User> => {
  return await customFetch(`${environments.apiUrl}/users`, {
    method: "POST",
    headers: {
      UserToken: token,
    },
    body: JSON.stringify({ token: token }),
  });
};
