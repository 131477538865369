import styled from "@emotion/native";
import React from "react";
import { useTranslation } from "react-i18next";
import colors from "../../../ui/constants/colors";
import { HeaderBack } from "../../../components/HeaderBack";
import Button from "../../../ui/Button";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Suspect, logger } from "../../../services";
import { InputSuspect } from "./components/InputSuspect";
import { Minus, Plus } from "phosphor-react-native";
import { getRandomAvatar, usePersistSuspects } from "./hooks/usePersistSuspects";
import fonts from "../../../ui/constants/fonts";
import { createGame } from "../../../services/api/games";
import { AppStackParamList } from "../../AppStackNavigator";
import { Screen } from "../../../ui/Screen";

const Container = styled.View`
  background-color: ${colors.surfaceBackground};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 16px;
`;

const CenterContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  gap: 48px;
`;

const Content = styled.ScrollView`
  flex: 1;
  height: 100%;
`;

const Bottom = styled.View`
  margin-top: 16px;
  background-color: ${colors.surfaceBackground};
`;

const ErrorText = styled.Text`
  color: ${colors.textBody};
  font-size: 14px;
  font-family: ${fonts.regular};
  position: absolute;
  top: -24px;
  right: 14px;
`;

const MoreButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: 16px 20px;
  gap: 12px;
  border-radius: 20px;
  border: 2px dashed #e5e5e5;
`;

const LessButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: 16px 20px;
  gap: 12px;
  border-radius: 20px;
  border: 2px dashed transparent;
`;

const Suspects = styled.View`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 32px;
`;

type Props = NativeStackScreenProps<AppStackParamList, "AddSuspects">;

export function AddSuspectsScreen({ navigation, route: { params } }: Props) {
  const { t } = useTranslation();
  const [suspects, setSuspects] = React.useState<Suspect[]>([]);
  const { loadingPersist } = usePersistSuspects(suspects, setSuspects);
  const [creatingGame, setCreatingGame] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  const submit = async () => {
    const alibiId = params?.alibiId;
    setCreatingGame(true);
    setError(false);
    try {
      const gameInfo = await createGame({ alibiId, suspects });
      navigation.navigate("WaitingRoom", { gameId: gameInfo.id });
    } catch (e) {
      logger.error(e, {
        file: "AddSuspectsScreen",
        function: "submit",
        message: "Error while creating game",
        level: "fatal",
      });
      setError(true);
    } finally {
      setCreatingGame(false);
    }
  };

  const addSuspect = () => {
    const newSuspects = [...suspects];
    newSuspects.push({
      id: String(suspects.length + 1),
      name: null,
      avatarId: getRandomAvatar(),
      teamNumber: suspects[suspects.length - 1].teamNumber,
      userId: null,
    });
    setSuspects(newSuspects);
  };

  const removeSuspect = () => {
    if (suspects.length > 2) {
      const newSuspects = [...suspects];
      newSuspects.pop();
      setSuspects(newSuspects);
    }
  };

  const onSuspectChange = (updatedSuspect: Suspect) => {
    const index = suspects.findIndex((suspect) => suspect.id === updatedSuspect.id);
    const newSuspects = [...suspects];
    newSuspects[index] = updatedSuspect;
    setSuspects(newSuspects);
  };

  const min2byTeam = checkTeams(suspects);
  const allSuspectWithAName = suspects.every((suspect) => suspect.name);

  return (
    <Screen>
      <Container>
        <HeaderBack title={t("Add suspects")} />
        <Content showsVerticalScrollIndicator={false}>
          {loadingPersist ? (
            <></>
          ) : (
            <>
              <Suspects>
                {suspects.map((suspect, index) => (
                  <InputSuspect
                    key={suspect.id}
                    suspect={suspect}
                    onSuspectChange={onSuspectChange}
                  />
                ))}
              </Suspects>
              <CenterContainer>
                <LessButton onPress={removeSuspect}>
                  <Minus color="#e5e5e5" weight="bold" />
                </LessButton>
                <MoreButton onPress={addSuspect}>
                  <Plus color="#e5e5e5" weight="bold" />
                </MoreButton>
              </CenterContainer>
            </>
          )}
        </Content>
        <Bottom>
          {error && <ErrorText>{t("Error while creating the game, please retry")}</ErrorText>}
          <Button
            disabled={!allSuspectWithAName || !min2byTeam}
            primary
            text={!min2byTeam ? t("Min 2 by team") : t("Create your game")}
            onPress={submit}
            loading={creatingGame}
            loadingText={t("Creating your game...")}
            testID="add-suspects-create-game-button"
          />
        </Bottom>
      </Container>
    </Screen>
  );
}
const checkTeams = (suspects: Suspect[]): boolean => {
  const teamCount = suspects.reduce((acc: { [key: number]: number }, suspect) => {
    acc[suspect.teamNumber] = (acc[suspect.teamNumber] || 0) + 1;
    return acc;
  }, {});

  return Object.values(teamCount).every((count) => count >= 2);
};
