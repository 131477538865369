import {
  Alibi,
  GameStatus,
  Question,
  Suspect,
  Vote,
  Response,
  TeamNumber,
  VoteType,
} from "../types";
import { GameRawData } from "./types";

const QUESTION_NUMBER = 10;

export type TeamResponses = {
  teamNumber: TeamNumber;
  responses: Response[];
  votes: Vote[];
  result: VoteType;
};

export type GameState = {
  question: Question;
  teamsResponses: TeamResponses[];
}[];

export type TeamResult = {
  teamNumber: TeamNumber;
  score: number;
  suspects: Suspect[];
  rank: number;
};

/**
 * This class is used to transform the raw data send in the event "game-updated" into a more usable format with getters
 */
export class Game {
  id: string;
  joinNumber: number;
  ownerId: string | null;
  status: GameStatus;
  responses: Response[];
  votes: Vote[];
  suspects: Suspect[];
  alibi: Alibi | null;

  state: GameState;

  constructor(gameRawData: GameRawData, alibi: Alibi | null) {
    this.id = gameRawData.gameInfo.id;
    this.joinNumber = gameRawData.gameInfo.joinNumber;
    this.ownerId = gameRawData.gameInfo.ownerId;
    this.status = gameRawData.gameInfo.status;
    this.suspects = gameRawData.suspects;
    this.responses = gameRawData.responses;
    this.votes = gameRawData.votes;

    this.alibi = alibi;

    if (alibi === null) {
      this.state = [];
      return;
    }

    this.state = this.buildState();
  }

  isEverybodyAnswered(): boolean {
    if (!this.suspects || this.suspects.length === 0) return false;
    if (!this.responses || this.responses.length === 0) return false;
    const question = this.getCurrentQuestion();
    const responses = this.responses.filter((response) => response.questionId === question?.id);
    if (responses.length === 0) return false;
    return responses.length % this.suspects.length === 0;
  }

  isEverybodyVoted(): boolean {
    if (!this.suspects || this.suspects.length === 0) return false;
    if (!this.votes || this.votes.length === 0) return false;
    const question = this.getCurrentQuestion();
    const votes = this.votes.filter((votes) => votes.questionId === question?.id);
    if (votes.length === 0) return false;
    const numberOfTeams = this.getTeamsNumber().length;
    const numberOfVotesByQuestion = this.suspects.length * numberOfTeams;
    return votes.length % numberOfVotesByQuestion === 0;
  }

  /*
   * Return the index of the current question
   * The current question is the first question where vote are incomplete
   */
  getCurrentQuestionIndex(): number {
    if (!this.responses || this.responses.length === 0) return 0;
    if (!this.votes || this.votes.length === 0) return 0;
    const numberOfTeams = this.getTeamsNumber().length;
    const numberOfVotesByQuestion = this.suspects.length * numberOfTeams;
    return Math.floor(this.votes.length / numberOfVotesByQuestion);
  }

  getQuestion(index: number): Question | null {
    if (this.state.length === 0) return null;
    if (!this.state[index]) return null;
    return this.state[index].question;
  }

  getCurrentQuestion(): Question | null {
    const questionIndex = this.getCurrentQuestionIndex();
    return this.getQuestion(questionIndex);
  }

  getTeamsResponses(questionIndex: number): TeamResponses[] {
    if (!this.state) return [];
    if (!this.state[questionIndex]) return [];
    return this.state[questionIndex].teamsResponses;
  }

  getResponses(questionIndex: number): Response[] {
    if (!this.responses || this.responses.length === 0) return [];
    const question = this.getQuestion(questionIndex);
    if (!question) return [];
    const responses = this.responses.filter((response) => response.questionId === question.id);
    return responses;
  }

  getTeamNumber(userId: string): number | null {
    const suspect = this.suspects.find((suspect) => suspect.userId === userId);
    return suspect ? suspect.teamNumber : null;
  }

  getSuspectTeamNumber(suspectId: string): number | null {
    const suspect = this.suspects.find((suspect) => suspect.id === suspectId);
    return suspect ? suspect.teamNumber : null;
  }

  getTeamsNumber(): TeamNumber[] {
    if (!this.suspects || this.suspects.length === 0) return [];
    const teams = this.suspects.map((suspect) => suspect.teamNumber);
    const uniqueTeams = [...new Set(teams)];
    const sortedUniqueTeams = uniqueTeams.sort((a, b) => a - b);
    return sortedUniqueTeams;
  }

  getSuspectById(suspectId: string): Suspect | null {
    const suspect = this.suspects.find((suspect) => suspect.id === suspectId);
    return suspect ? suspect : null;
  }

  getSuspectByUserId(userId: string): Suspect | null {
    const suspect = this.suspects.find((suspect) => suspect.userId === userId);
    return suspect ? suspect : null;
  }

  getSuspectId(userId: string): string | null {
    const suspect = this.suspects.find((suspect) => suspect.userId === userId);
    return suspect ? suspect.id : null;
  }

  getNumberOfQuestions(): number {
    return QUESTION_NUMBER;
  }

  // The captain is always the first suspect of the team
  getCaptainOfTheTeam(userId: string | null): Suspect | null {
    if (!userId) return null;
    const teamNumber = this.getTeamNumber(userId);
    const teamSuspects = this.suspects.filter((suspect) => suspect.teamNumber === teamNumber);

    if (!teamSuspects || teamSuspects.length === 0) {
      return null;
    }

    const captain = teamSuspects.reduce((prev, current) => {
      return prev.id.localeCompare(current.id) ? prev : current;
    });

    return captain;
  }

  getTeamsResults(): TeamResult[] {
    const teamsNumber = this.getTeamsNumber();
    const teamResults = teamsNumber.map((teamNumber) => {
      const teamSuspects = this.suspects.filter((suspect) => suspect.teamNumber === teamNumber);
      const teamScore = this.state.filter((question) => {
        const teamResponses = question.teamsResponses.find(
          (teamResponses) => teamResponses.teamNumber === teamNumber
        );
        if (!teamResponses) return false;
        return teamResponses.result === "UP";
      });

      return {
        teamNumber: teamNumber,
        rank: 0,
        score: teamScore.length,
        suspects: teamSuspects,
      };
    });

    // Sort by score in descending order
    teamResults.sort((a, b) => b.score - a.score);

    // Assign ranks
    let currentRank = 1;
    for (let i = 0; i < teamResults.length; i++) {
      if (i > 0 && teamResults[i].score === teamResults[i - 1].score) {
        teamResults[i].rank = teamResults[i - 1].rank;
      } else {
        teamResults[i].rank = currentRank;
      }
      currentRank++;
    }

    return teamResults;
  }

  private buildState() {
    if (!this.alibi) return [];
    return this.alibi.questions
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .slice(0, 10)
      .map((question) => {
        // All responses for this question
        const questionResponses = this.responses
          ? this.responses.filter((response) => response.questionId === question.id)
          : [];
        // All votes for this question
        const questionVotes = this.votes
          ? this.votes.filter((vote) => vote.questionId === question.id)
          : [];

        const teamsResponses = this.getTeamsNumber().map((teamNumber) => {
          // For each team get responses
          const teamResponses = questionResponses.filter((response) => {
            return this.getSuspectTeamNumber(response.suspectId) === teamNumber;
          });

          // For each team get votes
          const teamVotes = questionVotes.filter((vote) => vote.teamNumber === teamNumber);

          const isUp =
            teamVotes.filter((vote) => vote.voteType === "UP").length > teamVotes.length / 2;

          return {
            teamNumber: teamNumber,
            responses: teamResponses,
            votes: teamVotes,
            result: isUp ? ("UP" as VoteType) : ("DOWN" as VoteType),
          };
        });

        return {
          question: question,
          teamsResponses: teamsResponses,
        };
      });
  }
}
