import React, { useCallback, useEffect, useMemo, useState } from "react";
import RNRestart from "react-native-restart";
import { Screen } from "../../../ui/Screen";
import { useTranslation } from "react-i18next";
import styled from "@emotion/native";
import fonts from "../../../ui/constants/fonts";
import colors from "../../../ui/constants/colors";
import { useMe } from "../../../hooks/useMe";
import useGameManager from "../../../hooks/useGameManager";
import { Vote } from "../../../services";
import useGameRouter from "../hooks/useGameRouter";
import ErrorMsg from "../../../ui/ErrorMsg";
import environments from "../../../environments";
import { WaitingScreen } from "../components/WaitingScreen";
import { Image, ScrollView } from "react-native";
import { Response } from "./components/Response";
import ButtonRound from "./components/ButtonRound";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";

const Container = styled.View`
  padding: 16px;
  gap: 24px;
  height: 100%;
`;

const Header = styled.View`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  padding-bottom: 48px;
`;

const Title = styled.Text`
  font-size: 24px;
  font-family: ${fonts.extraBold};
  color: ${colors.textBody};
`;

const Question = styled.Text`
  font-size: 20px;
  font-family: ${fonts.semiBold};
  color: ${colors.textBody};
`;

const Responses = styled.View`
  gap: 8px;
  padding-bottom: 8px;
`;

const Rule = styled.Text`
  font-size: 16px;
  font-family: ${fonts.semiBold};
  color: ${colors.textSubtitle};
  text-align: center;
`;

const Judge = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 24px;
  padding-top: 24px;
`;

const Bottom = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  max-height: 500px;
  min-height: 200px;
`;

export function VoteScreen() {
  const { t } = useTranslation();
  const { token, userId } = useMe();
  const { game, sendVotes } = useGameManager({ userToken: token });
  useGameRouter({ game, currentScreen: "Vote" });
  const [error, setError] = useState<boolean>(false);
  const [isWaitingVisible, setIsWaitingVisible] = useState<boolean>(false);
  const questionIndex = game && game.getCurrentQuestionIndex();
  const teamsResponses = game && questionIndex !== null && game.getTeamsResponses(questionIndex);
  const mySuspectId = userId && game && game.getSuspectId(userId);
  const teamsResponsesToVote =
    teamsResponses &&
    teamsResponses.filter((teamResponse) => {
      return !teamResponse.votes?.find((vote) => {
        return vote.suspectId === mySuspectId;
      });
    });

  const [votes, setVotes] = useState<Vote[]>([]);

  useFocusEffect(
    useCallback(() => {
      setVotes([]);
      setIsWaitingVisible(false);
      setError(false);
      return () => {};
    }, [])
  );

  const vote = (voteType: "UP" | "DOWN") => {
    const questionId = game?.getCurrentQuestion()?.id;
    const mySuspectId = userId && game && game.getSuspectId(userId);

    // If the game is not loaded yet, don't do anything
    if (!questionId) return;
    if (!teamsResponsesToVote) return;
    if (!mySuspectId) return;

    const newVote: Vote = {
      suspectId: mySuspectId,
      questionId: questionId,
      teamNumber: teamsResponsesToVote[votes.length].teamNumber,
      voteType: voteType,
    };

    const newVotes = [...votes, newVote];

    // If all votes are in, send them to the server and switch on the waiting screen
    if (teamsResponsesToVote.length === newVotes.length) {
      setIsWaitingVisible(true);
      sendVotes(newVotes);
      setVotes([]);
      return;
    }
    setVotes(newVotes);
  };

  if (isWaitingVisible || (teamsResponsesToVote && teamsResponsesToVote.length === 0))
    return <WaitingScreen />;

  return (
    <Screen>
      {error ? (
        <ErrorMsg
          text={t("Server error")}
          retry={() => {
            if (environments.isMobile) {
              RNRestart.Restart();
            } else {
              window.location.reload();
            }
          }}
        />
      ) : (
        <ScrollView showsVerticalScrollIndicator={false}>
          <Container>
            <Header>
              <Image
                resizeMode="contain"
                source={require("../../../../assets/inspectors/inspector-base.png")}
                style={{ width: 60, height: 50 }}
              />
              <Title>{t("Judgment time!")}</Title>
            </Header>
            <Question>{game ? game.getCurrentQuestion()?.text : "Loading question..."}</Question>
            <Responses>
              {game &&
                teamsResponsesToVote &&
                teamsResponsesToVote.length > 0 &&
                teamsResponsesToVote[votes.length]?.responses?.map((response) => {
                  return (
                    <Response
                      text={response.text}
                      key={"responsesByTeam" + response.id}
                      suspectName={game.getSuspectById(response.suspectId)?.name || "Anonymous"}
                    />
                  );
                })}
            </Responses>
            <Rule>{t("If responses are nearly the same, it’s correct!")}</Rule>
            <Bottom>
              <Judge>
                <ButtonRound type="DOWN" onPress={() => vote("DOWN")} />
                <ButtonRound type="UP" onPress={() => vote("UP")} />
              </Judge>
            </Bottom>
          </Container>
        </ScrollView>
      )}
    </Screen>
  );
}
