import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { HomeScreen } from "./Home/HomeScreen";
import colors from "../ui/constants/colors";
import { SelectAlibiScreen } from "./CreateGame/1_SelectAlibi";
import { AddSuspectsScreen } from "./CreateGame/2_AddSuspects";
import { WaitingRoomScreen } from "./Game/1_WaitingRoom";
import { ReadAlibiScreen } from "./Game/2_ReadAlibi";
import { QuestionScreen } from "./Game/3_Question";
import { VoteScreen } from "./Game/4_Vote";
import { ResultsScreen } from "./Game/5_Results";
import { CongratulationsScreen } from "./Game/6_Congratulations";

export type AppStackParamList = {
  Home: {
    info?: "GAME_CANCELLED";
  };

  SelectAlibi: undefined;
  AddSuspects: {
    alibiId: string;
  };

  WaitingRoom: { gameId: string };
  ReadAlibi: undefined;
  Question: undefined;
  Vote: undefined;
  Results: undefined;
  Congratulations: undefined;
};

export const AppStack = createNativeStackNavigator<AppStackParamList>();

export function AppStackNavigator(): JSX.Element {
  return (
    <AppStack.Navigator
      initialRouteName="Home"
      screenOptions={{
        navigationBarHidden: true,
        navigationBarColor: colors.surfaceBackground,
      }}>
      <AppStack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          headerShown: false,
          fullScreenGestureEnabled: true,
          gestureEnabled: true,
        }}
      />

      <AppStack.Group
        screenOptions={{
          animation: "slide_from_right",
          headerShown: false,
        }}>
        <AppStack.Screen name="SelectAlibi" component={SelectAlibiScreen} />
        <AppStack.Screen name="AddSuspects" component={AddSuspectsScreen} />
      </AppStack.Group>

      <AppStack.Group
        screenOptions={{
          animation: "slide_from_right",
          headerShown: false,
        }}>
        <AppStack.Screen name="WaitingRoom" component={WaitingRoomScreen} />
        <AppStack.Screen name="ReadAlibi" component={ReadAlibiScreen} />
        <AppStack.Screen name="Question" component={QuestionScreen} />
        <AppStack.Screen name="Vote" component={VoteScreen} />
        <AppStack.Screen name="Results" component={ResultsScreen} />
        <AppStack.Screen name="Congratulations" component={CongratulationsScreen} />
      </AppStack.Group>
    </AppStack.Navigator>
  );
}
