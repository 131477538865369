import React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Image } from "react-native";
import { AppStackParamList } from "../../AppStackNavigator";
import { Screen } from "../../../ui/Screen";
import styled from "@emotion/native";
import { useTranslation } from "react-i18next";
import fonts from "../../../ui/constants/fonts";
import colors from "../../../ui/constants/colors";
import Button from "../../../ui/Button";
import useGameManager from "../../../hooks/useGameManager";
import { useMe } from "../../../hooks/useMe";
import { TeamAvatars } from "../../../components/TeamAvatars";
import { Suspect } from "../../../services";

const Content = styled.View`
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 0px;
  gap: 8px;
`;

const Title = styled.Text`
  font-family: ${fonts.extraBold};
  font-size: 36px;
  color: ${colors.textBody};
`;

const Top = styled.ScrollView``;

const Bottom = styled.View`
  display: flex;
  padding-top: 8px;
  gap: 8px;
`;

const Ladder = styled.View`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;

const Line = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
`;

const StartLine = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const Score = styled.Text<{ rank: number }>`
  font-family: ${fonts.bold};
  color: ${colors.textBody};
  font-size: 36px;

  ${({ rank }) => {
    switch (rank) {
      case 1:
        return `
          color: #FFD700;
        `;
      case 2:
        return `
          color: #CACACA;
        `;
      case 3:
        return `
          color: #FE9E27;
        `;
      default:
        return `
          color: ${colors.textBody};
        `;
    }
  }}
`;

const Position = styled.Text<{ rank: number }>`
  font-family: ${fonts.positions};
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ rank }) => {
    switch (rank) {
      case 1:
        return `
          font-size: 48px;
          color: #FFD700;
        `;
      case 2:
        return `
          font-size: 48px;
          color: #CACACA;
        `;
      case 3:
        return `
          font-size: 48px;
          color: #FE9E27;
        `;
      default:
        return `
          font-size: 32px;
          color: ${colors.textBody};
        `;
    }
  }}
`;

type Props = NativeStackScreenProps<AppStackParamList, "Congratulations">;

export function CongratulationsScreen({ navigation, route: { params } }: Props) {
  const { t } = useTranslation();
  const { token } = useMe();
  const { game, quitGame } = useGameManager({ userToken: token });
  const teamResult = game?.getTeamsResults();

  const quit = () => {
    quitGame();
    navigation.navigate("Home", {});
  };

  return (
    <Screen>
      <Content>
        <Top>
          <Header>
            <Image
              resizeMode="contain"
              source={require("../../../../assets/inspectors/inspector-well-done.png")}
              style={{ width: 120, height: 150 }}
            />
            <Title>{t("Well done!")}</Title>
          </Header>
          <Ladder>
            {teamResult &&
              teamResult.map((team) => {
                return (
                  <Line key={"resultLine" + team.teamNumber}>
                    <StartLine>
                      <Position rank={team.rank}>{team.rank}</Position>
                      <TeamAvatars suspects={team?.suspects as Suspect[]} />
                    </StartLine>
                    <Score rank={team.rank}>
                      {team.score}/{game?.getCurrentQuestionIndex()}
                    </Score>
                  </Line>
                );
              })}
          </Ladder>
        </Top>
        <Bottom>
          <Button text={t("Quit the game")} onPress={quit} primary />
        </Bottom>
      </Content>
    </Screen>
  );
}
