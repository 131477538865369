import { useCallback, useEffect, useState } from "react";
import GameManager from "../services/game-manager/game-manager";
import environments from "../environments";
import { AvailableLang, Vote, VoteType, logger } from "../services";
import { Game } from "../services/game-manager";

const WEBSOCKET_URL = environments.websocketUrl;

export type GameManagerHook = {
  joinGame: (gameId: string, lang: AvailableLang) => void;
  quitGame: () => void;
  selectSuspect: (suspectId: string) => void;
  startGame: () => void;
  sendResponse: (response: string, suspectId: string, questionId: string) => void;
  sendVotes: (votes: Vote[]) => void;
  game: Game | null;
  isReady: boolean;
};

function useGameManager({ userToken }: { userToken: string | null }): GameManagerHook {
  const [game, setGame] = useState<Game | null>(null);
  const [gameManager, setGameManager] = useState<GameManager | null>(null);

  useEffect(() => {
    if (!userToken) return;

    const gameManager = GameManager.getInstance({
      webSocketUrl: WEBSOCKET_URL,
      userToken: userToken,
    });
    const activeGame = gameManager.getActiveGame();

    setGame(activeGame);
    setGameManager(gameManager);
  }, [userToken]);

  useEffect(() => {
    if (!gameManager) return;

    const listenerGame = (_game: Game | null) => {
      setGame(_game);
    };

    gameManager.onGameUpdate(listenerGame);
    return () => {
      gameManager.removeListener(listenerGame);
    };
  }, [gameManager]);

  const executeAction = useCallback(
    (action: (gm: GameManager) => void, actionName: string) => {
      if (!gameManager) {
        logger.error("No game manager", {
          function: actionName,
          file: "useGameManager.ts",
          message: `No game manager while ${actionName}`,
        });
        return;
      }
      try {
        action(gameManager);
      } catch (e) {
        logger.error(e, {
          function: actionName,
          file: "useGameManager.ts",
          message: `Error ${actionName}`,
        });
      }
    },
    [gameManager]
  );

  const sendResponse = useCallback(
    (response: string, suspectId: string, questionId: string) =>
      executeAction((gm) => gm.sendResponse(response, suspectId, questionId), "sending response"),
    [executeAction]
  );

  const sendVotes = useCallback(
    (votes: Vote[]) => executeAction((gm) => gm.sendVotes(votes), "sending votes"),
    [executeAction]
  );

  const startGame = useCallback(
    () => executeAction((gm) => gm.startGame(), "starting the game"),
    [executeAction]
  );

  const joinGame = useCallback(
    (gameId: string, lang: AvailableLang) =>
      executeAction((gm) => gm.joinGame(gameId, lang), "joining the game"),
    [executeAction]
  );

  const quitGame = useCallback(
    () => executeAction((gm) => gm.quitGame(), "quitting the game"),
    [executeAction]
  );

  const selectSuspect = useCallback(
    (suspectId: string) => executeAction((gm) => gm.selectSuspect(suspectId), "selecting suspect"),
    [executeAction]
  );

  return {
    isReady: Boolean(gameManager),
    joinGame,
    game,
    quitGame,
    startGame,
    selectSuspect,
    sendResponse,
    sendVotes,
  };
}

export default useGameManager;
