import styled from "@emotion/native";
import React from "react";
import { Suspect } from "../../../../services";
import { useTranslation } from "react-i18next";
import Modal from "../../../../ui/Modal";
import fonts from "../../../../ui/constants/fonts";
import colors from "../../../../ui/constants/colors";
import { Avatar } from "../../../../components/Avatar";

const Content = styled.View``;

const Title = styled.Text`
  font-size: 20px;
  font-family: ${fonts.extraBold};
  text-transform: uppercase;
  color: ${colors.textBody};
`;

const Name = styled.Text`
  font-family: ${fonts.bold};
  font-size: 20px;
  color: ${colors.textBody};
`;

const SuspectLine = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
`;

type Props = {
  isVisible: boolean;
  suspects: Suspect[] | null;
  onClose?: () => void;
  onSelect: (suspect: Suspect) => void;
};

export function SelectSuspectModal({ isVisible, suspects, onClose, onSelect }: Props) {
  const { t } = useTranslation();

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Content testID="select-suspect-modal">
        <Title style={{ marginBottom: 28 }}>{t("Who are you?")}</Title>
        {suspects &&
          suspects.map((suspect) => (
            <SuspectLine
              key={suspect.id}
              onPress={() => onSelect(suspect)}
              testID={`select-suspect-${suspect.name}`}>
              <Avatar avatarId={suspect.avatarId} color={colors.team[suspect.teamNumber]} />
              <Name>{suspect.name}</Name>
            </SuspectLine>
          ))}
      </Content>
    </Modal>
  );
}
