import styled from "@emotion/native";
import React from "react";
import fonts from "../../ui/constants/fonts";
import colors from "../../ui/constants/colors";
import Modal from "../../ui/Modal";
import Button from "../../ui/Button";
import { useTranslation } from "react-i18next";

const Content = styled.View`
  gap: 24px;
`;

const Title = styled.Text`
  font-size: 20px;
  font-family: ${fonts.extraBold};
  text-transform: uppercase;
  color: ${colors.textBody};
`;

const Description = styled.Text`
  font-size: 16px;
  font-family: ${fonts.regular};
  color: ${colors.textBody};
`;

const Top = styled.View`
  gap: 12px;
`;

const Bottom = styled.View`
  gap: 12px;
`;

type Props = {
  title?: string;
  text?: string;
  isVisible: boolean;
  onClose?: () => void;
  yes: () => void;
  yesText?: string;
  no: () => void;
  noText?: string;
  testID?: string;
};

export function YesNoModal({
  isVisible,
  title,
  text,
  yes,
  yesText,
  no,
  noText,
  onClose,
  testID,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Content>
        <Top>
          <Title>{title}</Title>
          <Description>{text}</Description>
        </Top>
        <Bottom>
          <Button primary onPress={yes} text={yesText ?? t("Yes")} testID={testID + "-yes"} />
          <Button secondary onPress={no} text={noText ?? t("No")} testID={testID + "-no"} />
        </Bottom>
      </Content>
    </Modal>
  );
}
