import env from "../../environments";
import { getSessionId } from "../../utils/getSessionId";
import { Logger } from "./logger";
import { SentryLoggerProvider } from "./providers/sentry-logger-provider";
import { StdOutLoggerProvider } from "./providers/stdout-logger-provider";

const sessionId = getSessionId();

const loggerProviders = [new StdOutLoggerProvider()];

if (env.environment !== "dev") {
  loggerProviders.push(
    new SentryLoggerProvider({
      dns: env.sentryDns,
      sessionId: sessionId,
    })
  );
} else {
  console.warn("Sentry disabled");
}

export const logger = new Logger({
  loggerProviders,
});
