import React, { useEffect } from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { AppStackParamList } from "../../AppStackNavigator";
import { Screen } from "../../../ui/Screen";
import styled from "@emotion/native";
import { Chrono } from "./components/Chrono";
import fonts from "../../../ui/constants/fonts";
import { HorizontalLines } from "./components/HorizontalLines";
import { LayoutChangeEvent } from "react-native";
import { InstructionsModal } from "./components/InstructionsModal";
import useGameManager from "../../../hooks/useGameManager";
import { useMe } from "../../../hooks/useMe";
import { TeamAvatars } from "../../../components/TeamAvatars";
import useGameRouter from "../hooks/useGameRouter";
import { getItemSecure, setItemSecure } from "../../../utils/secureStore";
import { useIsFocused } from "@react-navigation/native";

const Content = styled.ScrollView`
  padding: 16px;
`;

const ChronoHeader = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 84px;
  width: 100%;
`;

const Alibi = styled.View`
  background-color: #fffaf6;
  padding: 50px 30px 30px 30px;
  margin-top: 28px;
  position: relative;
  overflow: hidden;
`;

const AlibiTitle = styled.Text`
  color: #373737;
  font-family: ${fonts.regular};
  font-size: 24px;
  letter-spacing: -2.4px;
  padding-left: 8px;
  padding-bottom: 16px;
`;

const AlibiText = styled.Text`
  color: #373737;
  font-family: ${fonts.regular};
  font-size: 16px;
  line-height: 23px;
  padding: 4px 2px;
`;

const TopRightImage = styled.Image`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 100px;
  height: 100px;
`;

const TeamAvatarsContainer = styled.View`
  position: absolute;
  z-index: 10000;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 50%;
`;

const TeamAvatarsContent = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

type Props = NativeStackScreenProps<AppStackParamList, "ReadAlibi">;

const ALIBI_DURATION_SECONDS = 60;

export function ReadAlibiScreen({ navigation, route: { params } }: Props) {
  const [alibiHeight, setAlibiHeight] = React.useState(0);
  const [totalTime, setTotalTime] = React.useState(0);
  const { token, userId } = useMe();
  const { game } = useGameManager({ userToken: token });
  useGameRouter({ game, currentScreen: "ReadAlibi" });
  const teamNumber = userId && game?.getTeamNumber(userId);
  const captain = game?.getCaptainOfTheTeam(userId);

  const [instructionIsVisible, setInstructionIsVisible] = React.useState(true);

  const onAlibiLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setAlibiHeight(height);
  };

  useEffect(() => {
    if (!game) return;
    if (navigation.isFocused() === false) return;
    getItemSecure("hasSeenAlibi").then((hasSeenAlibi) => {
      if (hasSeenAlibi === game?.id) {
        navigation.navigate("Question");
      }
    });
  }, [game, navigation]);

  const onFinish = () => {
    if (game?.id) setItemSecure("hasSeenAlibi", game?.id);
    navigation.navigate("Question");
  };

  return (
    <Screen>
      {instructionIsVisible && (
        <TeamAvatarsContainer>
          <TeamAvatarsContent>
            <TeamAvatars
              suspects={game?.suspects.filter((suspect) => suspect.teamNumber == teamNumber)}
            />
          </TeamAvatarsContent>
        </TeamAvatarsContainer>
      )}
      <InstructionsModal
        isVisible={instructionIsVisible}
        // Only captains will have alibi displayed
        isCaptain={true /*captain?.userId === userId*/}
        captainName={captain ? captain.name : null}
        // If the suspect is the captain
        onStart={() => {
          setTotalTime(ALIBI_DURATION_SECONDS);
          setInstructionIsVisible(false);
        }}
        alibiDuration={ALIBI_DURATION_SECONDS}
        // Else if the suspect is not the captain
        onNext={() => {
          onFinish();
        }}
      />
      <Content
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}>
        <ChronoHeader>
          <Chrono totalTime={totalTime} onTimeFinished={onFinish} />
        </ChronoHeader>
        <Alibi onLayout={onAlibiLayout}>
          <TopRightImage
            resizeMode="contain"
            source={require("../../../../assets/imgs/top-right-alibi.svg")}
            style={{ width: 84, height: 84 }}
          />
          {alibiHeight > 0 && <HorizontalLines height={alibiHeight - 44} lineSpacing={22} />}
          <AlibiTitle>{game?.alibi?.name}</AlibiTitle>
          <AlibiText>{game?.alibi?.text}</AlibiText>
        </Alibi>
      </Content>
    </Screen>
  );
}
