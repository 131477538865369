import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Suspect, isSuspect, logger } from "../../../../services";

export const getRandomAvatar = () => {
  return Math.floor(Math.random() * 10) + 1;
};

export function usePersistSuspects(
  suspects: Suspect[],
  setSuspects: (suspects: Suspect[]) => void
) {
  const [loadingPersist, setLoadingPersist] = useState<boolean>(true);

  useEffect(() => {
    const loadSuspects = async () => {
      let isDefault = true;
      try {
        const jsonSuspects = await AsyncStorage.getItem("suspects");
        if (jsonSuspects) {
          const storedSuspects = JSON.parse(jsonSuspects);
          // Invalidate the async storage if the type does not match
          // This can occurred if the type of the suspect has changed
          for (const suspect of storedSuspects) {
            if (!isSuspect(suspect)) {
              throw new Error("Invalid suspect type");
            }
          }
          isDefault = false;
          setSuspects(storedSuspects);
        }
      } catch (e) {
        logger.error(e, {
          file: "AddSuspectsScreen",
          function: "loadSuspects",
          message: "Error while loading suspects from AsyncStorage",
        });
      } finally {
        setLoadingPersist(false);
        if (isDefault)
          setSuspects([
            {
              id: "1",
              name: null,
              avatarId: getRandomAvatar(),
              teamNumber: 1,
              userId: null,
            },
            {
              id: "2",
              name: null,
              avatarId: getRandomAvatar(),
              teamNumber: 1,
              userId: null,
            },
            {
              id: "3",
              name: null,
              avatarId: getRandomAvatar(),
              teamNumber: 2,
              userId: null,
            },
            {
              id: "4",
              name: null,
              avatarId: getRandomAvatar(),
              teamNumber: 2,
              userId: null,
            },
          ]);
      }
    };
    loadSuspects();
  }, [setSuspects]);

  useEffect(() => {
    const saveSuspects = async () => {
      try {
        if (suspects.length === 0) return;
        await AsyncStorage.setItem("suspects", JSON.stringify(suspects));
      } catch (e) {
        logger.error(e, {
          file: "AddSuspectsScreen",
          function: "loadSuspects",
          message: "Error while saving suspects from AsyncStorage",
        });
      }
    };
    saveSuspects();
  }, [suspects]);

  return { loadingPersist, suspects };
}
