import styled from "@emotion/native";
import colors from "../constants/colors";
import { ReactNode } from "react";
import environments from "../../environments";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const Container = styled.View<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Content = styled.View<{ paddingTop: number; paddingBottom: number; bgColor: string }>`
  flex: 1;
  max-width: 500px;
  width: 100%;
  height: 100%;
  background-color: ${({ bgColor }) => bgColor};
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : "0px")};
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? `${paddingBottom}px` : "0px")};
`;

const IS_MOBILE = environments.isMobile;

export const Screen = ({
  children,
  bgColor = colors.surfaceBackground,
}: {
  children: ReactNode;
  bgColor?: string;
}): JSX.Element => {
  const insets = useSafeAreaInsets();

  const paddingBottom = IS_MOBILE ? insets.bottom : 32;

  return (
    <Container bgColor={bgColor}>
      <Content paddingTop={insets.top} paddingBottom={paddingBottom} bgColor={bgColor}>
        {children}
      </Content>
    </Container>
  );
};
