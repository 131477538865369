import React, { useCallback, useEffect, useRef } from "react";
import { ActivityIndicator, Animated } from "react-native";

import styled from "@emotion/native";
import DialSvg from "./components/DialSvg";
import HandSvg from "./components/HandSvg";
import colors from "../constants/colors";

const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 45px;
  height: 45px;
`;

const DialContainer = styled.View``;

const HandContainer = styled.View`
  position: absolute;
  bottom: 16px;
  right: 11px;
`;

type Props = {
  size?: "small" | "large";
};

export default function Loader({ size = "large" }: Props): JSX.Element {
  // const rotation = useRef(new Animated.Value(0)).current;
  // const currentTick = useRef(1);

  // const startTickAnimation = useCallback(() => {
  //   const nextValue = currentTick.current * (360 / 4);
  //   currentTick.current = (currentTick.current + 1) % 5;

  //   if (nextValue == 360 / 4) {
  //     rotation.setValue(0);
  //   }

  //   console.log("currentTick.current", currentTick.current);
  //   console.log("nextValue", nextValue);
  //   Animated.timing(rotation, {
  //     toValue: nextValue,
  //     duration: 2000,
  //     useNativeDriver: true,
  //   }).start(() => startTickAnimation());
  // }, [rotation]);

  // useEffect(() => {
  //   startTickAnimation();
  // }, [startTickAnimation]);

  // const rotationInterpolate = rotation.interpolate({
  //   inputRange: [0, 360],
  //   outputRange: ["0deg", "360deg"],
  //   extrapolate: "identity",
  // });

  return (
    <ActivityIndicator size={size} color={colors.textBody} />
    // <Container>
    //   <DialContainer>
    //     <DialSvg />
    //   </DialContainer>
    //   <HandContainer>
    //     <Animated.View style={{ transform: [{ rotate: rotationInterpolate }] }}>
    //       <HandSvg />
    //     </Animated.View>
    //   </HandContainer>
    // </Container>
  );
}
