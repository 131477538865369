import environments from "../../environments";
import { getUserToken } from "../me/getUserToken";
import { GameInfo, GameState, Suspect } from "../types";
import { customFetch } from "./utils/customFetch";

export const createGame = async ({
  alibiId,
  suspects,
}: {
  alibiId: string;
  suspects: Suspect[];
}): Promise<GameInfo> => {
  const token = await getUserToken();
  const gameInfo = await customFetch<GameInfo>(`${environments.apiUrl}/games`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      UserToken: token,
    },
    body: JSON.stringify({ alibiId, suspects }),
  });
  return gameInfo;
};

export const quitGame = async ({ gameId }: { gameId: string }): Promise<boolean> => {
  const token = await getUserToken();
  return await customFetch<boolean>(`${environments.apiUrl}/games`, {
    method: "DELETE",
    headers: {
      UserToken: token,
    },
    body: JSON.stringify({ gameId }),
  });
};

export const getMyActiveGameInfo = async (): Promise<GameInfo | null> => {
  const token = await getUserToken();
  const gameInfo = await customFetch<GameInfo>(`${environments.apiUrl}/games/me/active`, {
    headers: {
      UserToken: token,
    },
  });
  return gameInfo;
};

export const getGameId = async ({ joinNumber }: { joinNumber: string }): Promise<string | null> => {
  const token = await getUserToken();
  return await customFetch<string>(`${environments.apiUrl}/games?joinNumber=${joinNumber}`, {
    headers: {
      UserToken: token,
    },
  });
};
