import { useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { AppStackParamList } from "../../AppStackNavigator";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Game } from "../../../services/game-manager";

type ScreenName = "WaitingRoom" | "ReadAlibi" | "Question" | "Vote" | "Results" | "Congratulations";

function useGameRouter({
  game,
  currentScreen,
}: {
  game: Game | null;
  currentScreen: ScreenName;
}): void {
  const navigation = useNavigation<NativeStackNavigationProp<AppStackParamList>>();

  useEffect(() => {
    if (!game || !navigation || !currentScreen) return;
    if (navigation.isFocused() === false) return;

    console.log("Router triggered ", currentScreen);

    if (game.status === "CANCELLED") {
      navigation.push("Home", {
        info: "GAME_CANCELLED",
      });
      return;
    }

    if (currentScreen === "ReadAlibi") {
      return;
    }

    if (game.status === "STARTED") {
      if (currentScreen === "WaitingRoom") {
        console.log(`Router:  ${currentScreen} => ReadAlibi`);
        navigation.push("ReadAlibi");
        return;
      }
      if (!game.isEverybodyAnswered()) {
        if (currentScreen !== "Question") {
          console.log(`Router:  ${currentScreen} => Question`);
          navigation.push("Question");
        }
        return;
      }
      if (!game.isEverybodyVoted()) {
        if (currentScreen !== "Vote") {
          console.log(`Router: ${currentScreen} => Vote`);
          navigation.push("Vote");
        }
        return;
      }
    }

    if (game.status === "FINISHED") {
      if (currentScreen !== "Congratulations") {
        console.log(`Router: ${currentScreen} => Congratulations`);
        navigation.push("Congratulations");
      }
      return;
    }
  }, [navigation, game, currentScreen]);
}

export default useGameRouter;
