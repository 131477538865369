import { useEffect, useState } from "react";
import { AvailableLang } from "../services/i18n";
import { PublicAlibi } from "../services/types";
import { logger } from "../services/logger";
import { getAllPublicAlibis } from "../services/api/alibis";

export function usePublicAlibis(lang: AvailableLang) {
  const [publicAlibis, setPublicAlibis] = useState<PublicAlibi[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown | null>(null);
  const [retryCount, setRetryCount] = useState<number>(0);

  useEffect(() => {
    let ignore = false;

    (async () => {
      try {
        const alibis = await getAllPublicAlibis({ lang });
        if (ignore) return;
        setError(null);
        setPublicAlibis(alibis);
      } catch (err: unknown) {
        if (ignore) return;
        logger.error(err, {
          message: "Error while fetching public alibis",
          file: "usePublicAlibis",
          function: "useEffect",
          extraData: {
            retry: retryCount,
            lang: lang,
          },
          level: retryCount > 2 ? "fatal" : "error",
        });
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      ignore = true;
    };
  }, [lang, retryCount]);

  const retry = () => {
    setLoading(true);
    setRetryCount((prev) => prev + 1);
  };

  return { publicAlibis, loading, error, retry };
}
