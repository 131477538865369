import React, { useCallback } from "react";
import { Image } from "react-native";
import { Screen } from "../../../ui/Screen";
import styled from "@emotion/native";
import fonts from "../../../ui/constants/fonts";
import colors from "../../../ui/constants/colors";
import { useTranslation } from "react-i18next";
import useGameManager from "../../../hooks/useGameManager";
import { useMe } from "../../../hooks/useMe";
import { ResultResponse } from "./components/ResultResponse";
import { Avatar } from "../../../components/Avatar";
import { CorrectScreen } from "../components/CorrectScreen";
import { WrongScreen } from "../components/WrongScreen";
import Button from "../../../ui/Button";
import { AppStackParamList } from "../../AppStackNavigator";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useFocusEffect } from "@react-navigation/native";
import useGameRouter from "../hooks/useGameRouter";

const Container = styled.View`
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.View`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  padding-bottom: 48px;
`;

const Title = styled.Text`
  font-size: 24px;
  font-family: ${fonts.extraBold};
  color: ${colors.textBody};
`;

const Question = styled.Text`
  font-size: 20px;
  font-family: ${fonts.semiBold};
  color: ${colors.textBody};
`;

const Responses = styled.View`
  gap: 32px;
  padding-bottom: 8px;
`;

const TeamResponses = styled.View`
  gap: 8px;
`;

const Voters = styled.View`
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
`;

const Top = styled.ScrollView``;
const Bottom = styled.View`
  padding-top: 8px;
`;

type Props = NativeStackScreenProps<AppStackParamList, "Results">;

export function ResultsScreen({ navigation }: Props) {
  const { t } = useTranslation();
  const { token, userId } = useMe();
  const { game } = useGameManager({ userToken: token });
  useGameRouter({ game, currentScreen: "Results" });
  const questionIndex = game?.getCurrentQuestionIndex();
  // We get the -1 to get the result of the last question
  const myTeamNumber = userId && game?.getSuspectByUserId(userId)?.teamNumber;
  const teamsResponses = questionIndex && game?.getTeamsResponses(questionIndex - 1);
  const result =
    myTeamNumber &&
    teamsResponses &&
    teamsResponses.find((teamResponses) => teamResponses.teamNumber === myTeamNumber)?.result;
  const [resultIsVisible, setResultIsVisible] = React.useState<boolean>(true);

  const submit = () => {
    if (game && game.status === "FINISHED") {
      navigation.navigate("Congratulations");
      return;
    }
    navigation.navigate("Question");
  };

  useFocusEffect(
    useCallback(() => {
      setResultIsVisible(true);
      return () => {};
    }, [])
  );

  if (resultIsVisible && result === "UP")
    return <CorrectScreen onPress={() => setResultIsVisible(false)} />;
  if (resultIsVisible && result === "DOWN")
    return <WrongScreen onPress={() => setResultIsVisible(false)} />;

  return (
    <Screen>
      <Container>
        <Top
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            width: "100%",
            gap: 24,
          }}>
          <Header>
            <Image
              resizeMode="contain"
              source={require("../../../../assets/inspectors/inspector-base.png")}
              style={{ width: 60, height: 50 }}
            />
            <Title>
              {t("Question N°")}
              {game ? game.getCurrentQuestionIndex() : 0} /{" "}
              {game ? game.getNumberOfQuestions() : "10"}
            </Title>
          </Header>
          <Question>{game ? game.getCurrentQuestion()?.text : "Loading question..."}</Question>
          <Responses>
            {game &&
              teamsResponses &&
              teamsResponses?.map((teamResponses, index) => {
                return (
                  <TeamResponses key={"responsesByTeam" + index}>
                    {teamResponses.responses.map((response, index) => {
                      return (
                        <ResultResponse
                          text={response.text}
                          key={"response" + index}
                          isSuccess={teamResponses.result === "UP"}
                          isYou={userId === game.getSuspectById(response.suspectId)?.userId}
                          suspect={game && game.getSuspectById(response.suspectId)}
                        />
                      );
                    })}
                    <Voters>
                      {teamResponses.votes
                        .filter((vote) => vote.voteType === teamResponses.result)
                        .map((vote, index) => {
                          const suspect = game.getSuspectById(vote.suspectId);
                          if (!suspect) return null;
                          return (
                            <Avatar
                              key={"vote" + index}
                              size={28}
                              avatarId={suspect.avatarId}
                              color={colors.team[suspect.teamNumber]}
                            />
                          );
                        })}
                    </Voters>
                  </TeamResponses>
                );
              })}
          </Responses>
        </Top>
        <Bottom>
          <Button
            primary
            text={game && game.status === "FINISHED" ? t("See leaderboard") : t("Next question")}
            onPress={submit}
          />
        </Bottom>
      </Container>
    </Screen>
  );
}
