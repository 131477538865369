import styled from "@emotion/native";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "../../../ui/constants/colors";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import fonts from "../../../ui/constants/fonts";
import { CopySimple } from "phosphor-react-native";
import { SuspectWaitingLine } from "./components/SuspectWaitingLine";
import Button from "../../../ui/Button";
import { View } from "react-native";
import { useMe } from "../../../hooks/useMe";
import useGameRouter from "../hooks/useGameRouter";
import { AppStackParamList } from "../../AppStackNavigator";
import { Screen } from "../../../ui/Screen";
import useGameManager from "../../../hooks/useGameManager";
import { SelectSuspectModal } from "./components/SelectSuspect";
import { AvailableLang } from "../../../services";
import Loader from "../../../ui/Loader";
import { useIsFocused } from "@react-navigation/native";

const Container = styled.View`
  background-color: ${colors.surfaceBackground};
  display: flex;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 16px;
`;

const Header = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 0px;
  margin-bottom: 16px;
`;

const Cancel = styled.TouchableOpacity``;

const CancelText = styled.Text`
  font-size: 20px;
  font-family: ${fonts.extraBold};
  color: ${colors.textBody};
  text-transform: uppercase;
`;

const JoinNumberContainer = styled.View`
  background-color: ${colors.surfaceSecondary};
  padding: 24px 0px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const JoinNumberLinks = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Link = styled.Text`
  font-size: 16px;
  font-family: ${fonts.regular};
  color: ${colors.textBody};
  text-decoration-line: underline;
`;

const JoinNumberText = styled.Text`
  font-size: 20px;
  font-family: ${fonts.extraBold};
  color: ${colors.textBody};
  text-transform: uppercase;
`;

const Copy = styled.TouchableOpacity``;

const SuspectList = styled.ScrollView`
  display: flex;
  flex-direction: column;
  padding-top: 39px;
  padding-bottom: 32px;
`;

const Divider = styled.View<{ height: number }>`
  height: ${({ height }) => height + "px"};
  width: 100%;
`;

const Bottom = styled.View`
  padding: 12px 0px;
`;

const LoadingContent = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  height: 80%;
`;

const LoadingText = styled.Text`
  font-size: 16px;
  font-family: ${fonts.regular};
  color: ${colors.textBody};
  text-align: center;
`;

type Props = NativeStackScreenProps<AppStackParamList, "WaitingRoom">;

export function WaitingRoomScreen({ navigation, route: { params } }: Props) {
  const { t, i18n } = useTranslation();
  const { userId, token } = useMe();
  const { isReady, game, joinGame, quitGame, selectSuspect, startGame } = useGameManager({
    userToken: token,
  });
  useGameRouter({ game, currentScreen: "WaitingRoom" });

  const [isSelectSuspectVisible, setSelectSuspectVisible] = useState(false);

  const joinNumber = game?.joinNumber;
  const isOwner = game && game?.ownerId == userId;
  const isReadyToStart =
    game && game.suspects.filter((suspect) => suspect.userId).length == game.suspects.length;
  const suspectToSelect = game && game.suspects.filter((suspect) => !suspect.userId);

  const quit = () => {
    quitGame();
    navigation.navigate("Home", {});
  };

  useEffect(() => {
    if (!joinGame) return;
    if (!isReady) return;
    if (!params.gameId) return;
    if (!i18n.language) return;
    joinGame(params.gameId, i18n.language as AvailableLang);
  }, [isReady, joinGame, quitGame, params.gameId, i18n.language]);

  useEffect(() => {
    if (!userId) return;
    if (!game) return;
    // If the user is already in the game
    if (game.getSuspectId(userId)) return;
    // If the game is full
    if (game.suspects.filter((suspect) => !suspect.userId).length == 0) return;
    setSelectSuspectVisible(true);
  }, [game, userId]);

  if (!game) {
    return (
      <Screen>
        <LoadingContent>
          <Loader size={"small"} />
          <LoadingText>{t("Loading your game...")}</LoadingText>
        </LoadingContent>
      </Screen>
    );
  }

  return (
    <Screen>
      <SelectSuspectModal
        isVisible={isSelectSuspectVisible && (suspectToSelect?.length ?? 0) > 0}
        suspects={suspectToSelect}
        onSelect={(suspect) => {
          setSelectSuspectVisible(false);
          selectSuspect(suspect.id);
        }}
      />
      <Container>
        <Header>
          {game && (
            <Cancel onPress={quit}>
              <CancelText>{isOwner ? t("Cancel the game") : t("Quit the game")}</CancelText>
            </Cancel>
          )}
        </Header>
        <JoinNumberContainer>
          <JoinNumberText>{t("Game number")}</JoinNumberText>
          <JoinNumberText testID="join-number-text">
            {joinNumber && formattedJoinNumber(joinNumber)}
          </JoinNumberText>
          <JoinNumberLinks>
            <Link>alibi-game.com</Link>
            <Copy>
              <CopySimple size={20} color={colors.textBody} />
            </Copy>
          </JoinNumberLinks>
        </JoinNumberContainer>
        <SuspectList showsVerticalScrollIndicator={false}>
          {game &&
            game.suspects &&
            game.suspects
              .sort((a, b) => a.id.localeCompare(b.id))
              .map((suspect, index) => {
                return (
                  <View key={suspect.id + index}>
                    <SuspectWaitingLine
                      isYou={suspect.userId === userId}
                      suspect={suspect}
                      status={suspect.userId ? "arrived" : "waiting"}
                    />
                    <Divider height={12} />
                  </View>
                );
              })}
          <Divider height={30} />
        </SuspectList>
        <Bottom>
          {isOwner && (
            <Button
              primary
              text={"Start the game"}
              disabled={!isReadyToStart}
              loadingText={"Waiting suspects..."}
              onPress={() => startGame()}
            />
          )}
        </Bottom>
      </Container>
    </Screen>
  );
}

function formattedJoinNumber(num: number) {
  if (num >= 1000000) {
    return num;
  }
  const formatted = String(num).padStart(6, "0");
  return formatted.slice(0, 3) + " " + formatted.slice(3);
}
