import React from "react";
import styled from "@emotion/native";
import { AvailableLang } from "../../../services";
import Modal from "../../../ui/Modal";
import fonts from "../../../ui/constants/fonts";
import colors from "../../../ui/constants/colors";
import { Image, ImageSourcePropType } from "react-native";
import { Check } from "phosphor-react-native";

const LanguageList = styled.View`
  display: flex;
  gap: 8px;
`;

const Language = styled.TouchableOpacity<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  padding: 16px;

  ${(props) =>
    props.isSelected &&
    `
    background-color: ${colors.surfaceBackground};`}
`;

const Left = styled.View`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const LanguageName = styled.Text`
  font-size: 20px;
  font-family: ${fonts.extraBold};
  color: ${colors.textBody};
  text-transform: uppercase;
`;

type Props = {
  isVisible: boolean;
  languageSelected: AvailableLang;
  onLanguageSelected: (lang: AvailableLang) => void;
  onClose: () => void;
};

export const Languages: { name: string; code: AvailableLang; image: ImageSourcePropType }[] = [
  {
    name: "English",
    code: AvailableLang.en,
    image: require("../../../../assets/flags/en.png"),
  },
  {
    name: "Español",
    code: AvailableLang.es,
    image: require("../../../../assets/flags/es.jpeg"),
  },
  {
    name: "Français",
    code: AvailableLang.fr,
    image: require("../../../../assets/flags/fr.png"),
  },
  {
    name: "Portuguese",
    code: AvailableLang.pt,
    image: require("../../../../assets/flags/pt.png"),
  },
];

export default function LanguageModal({
  isVisible,
  languageSelected,
  onLanguageSelected,
  onClose,
}: Props) {
  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <LanguageList>
        {Languages.map((lang) => {
          return (
            <Language
              onPress={() => onLanguageSelected(lang.code)}
              key={lang.code}
              isSelected={languageSelected === lang.code}>
              <Left>
                <Image source={lang.image} style={{ width: 33, height: 21 }} />
                <LanguageName>{lang.name}</LanguageName>
              </Left>
              {languageSelected === lang.code ? (
                <Check color={colors.textBody} weight="bold" size={27} />
              ) : (
                <></>
              )}
            </Language>
          );
        })}
      </LanguageList>
    </Modal>
  );
}
