import React from "react";
import styled from "@emotion/native";
import { Pressable, StyleProp, ViewStyle } from "react-native";
import colors from "../../constants/colors";
import fonts from "../../constants/fonts";

const ButtonContainer = styled.View`
  height: 52px;
  width: 100%;
  position: relative;
`;

const Foreground = styled.View<{ pressed: boolean; color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  min-width: 200px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  border-radius: 20px;
  background-color: ${({ color }) => color};
  transform: ${(props) => (props.pressed ? "translateY(4px)" : "translateY(0px)")};
`;

const Background = styled.View`
  height: 48px;
  min-width: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.33);
`;

const BackgroundFill = styled.View<{ color: string }>`
  height: 48px;
  min-width: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
  z-index: 2;
  background-color: ${({ color }) => color};
`;

const ButtonText = styled.Text`
  font-size: 20px;
  text-transform: uppercase;
  font-family: ${fonts.extraBold};
  color: ${colors.textPrimary};
`;

type Props = {
  text?: string;
  disabled?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

export default function ButtonPrimary({
  text,
  onPress,
  style,
  disabled,
  testID,
}: Props): JSX.Element {
  const color = disabled ? colors.surfaceDisabled : colors.surfacePrimary;
  return (
    <Pressable onPress={onPress} disabled={disabled} testID={testID}>
      {({ pressed }) => (
        <ButtonContainer style={style}>
          <Foreground pressed={pressed} color={color}>
            <ButtonText>{text}</ButtonText>
          </Foreground>
          <BackgroundFill color={color} />
          <Background />
        </ButtonContainer>
      )}
    </Pressable>
  );
}
