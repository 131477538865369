import styled from "@emotion/native";
import React from "react";
import { useTranslation } from "react-i18next";
import colors from "../../../ui/constants/colors";
import { HeaderBack } from "../../../components/HeaderBack";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { AppStackParamList } from "../../AppStackNavigator";
import { usePublicAlibis } from "../../../hooks/usePublicAlibis";
import { AvailableLang, PublicAlibi } from "../../../services";
import { AlibiCard } from "./components/AlibiCard";
import { FlatList, View } from "react-native";
import fonts from "../../../ui/constants/fonts";
import ErrorMsg from "../../../ui/ErrorMsg";
import { Screen } from "../../../ui/Screen";

const Container = styled.View`
  background-color: ${colors.surfaceBackground};
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px;
`;

const Header = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px 0px;
`;

const HeaderText = styled.Text`
  font-family: ${fonts.regular};
  color: ${colors.textBody};
`;

type Props = NativeStackScreenProps<AppStackParamList, "SelectAlibi">;

export function SelectAlibiScreen({ navigation, route: { params } }: Props) {
  const { t, i18n } = useTranslation();
  const { publicAlibis, error, retry } = usePublicAlibis(i18n.language as AvailableLang);

  const goToNextPage = (alibiId: string) => {
    navigation.navigate("AddSuspects", {
      alibiId,
    });
  };

  const renderItem = ({ item }: { item: PublicAlibi }) => {
    if (!item) return <></>;
    return (
      <View style={{ marginBottom: 10 }}>
        <AlibiCard alibi={item} key={item.id} onPress={() => goToNextPage(item.id)} />
      </View>
    );
  };

  return (
    <Screen>
      <Container>
        <HeaderBack title={t("Select an alibi")} />
        {error ? (
          <ErrorMsg text={t("Server error")} retry={retry} />
        ) : (
          <>
            <Header>
              <HeaderText>{t("Difficulty")}</HeaderText>
            </Header>
            <FlatList
              data={publicAlibis}
              renderItem={renderItem}
              keyExtractor={(item) => item?.id?.toString()}
              onEndReachedThreshold={0.5}
            />
          </>
        )}
      </Container>
    </Screen>
  );
}
