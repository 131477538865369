import React from "react";
import styled from "@emotion/native";
import fonts from "../../../../ui/constants/fonts";
import colors from "../../../../ui/constants/colors";
import { Check, X } from "phosphor-react-native";
import { Pressable } from "react-native";

const ButtonContainer = styled.View`
  height: 100px;
  width: 100px;
  position: relative;
`;

const Foreground = styled.View<{ pressed: boolean; color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 94px;
  width: 94px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  border-radius: 94px;
  background-color: ${({ color }) => color};
  transform: ${(props) => (props.pressed ? "translateY(6px)" : "translateY(0px)")};
`;

const Background = styled.View`
  height: 94px;
  width: 94px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 94px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.33);
`;

const BackgroundFill = styled.View<{ color: string }>`
  height: 94px;
  width: 94px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 94px;
  z-index: 2;
  background-color: ${({ color }) => color};
`;

const ButtonText = styled.Text`
  font-size: 20px;
  text-transform: uppercase;
  font-family: ${fonts.extraBold};
  color: ${colors.textPrimary};
`;

type Props = {
  type: "UP" | "DOWN";
  onPress: () => void;
  testID?: string;
};

export default function ButtonRound({ type, onPress, testID }: Props): JSX.Element {
  const color = type === "UP" ? colors.statusSuccess : colors.statusError;

  return (
    <Pressable onPress={onPress} testID={testID}>
      {({ pressed }) => (
        <ButtonContainer>
          <Foreground pressed={pressed} color={color}>
            {type === "DOWN" && <X color={colors.textBody} size={54} weight="bold" />}
            {type === "UP" && <Check color={colors.textBody} size={54} weight="bold" />}
          </Foreground>
          <BackgroundFill color={color} />
          <Background />
        </ButtonContainer>
      )}
    </Pressable>
  );
}
