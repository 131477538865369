import styled from "@emotion/native";
import React from "react";

const Container = styled.View`
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
`;

const Line = styled.View<{ lineSpacing: number }>`
  border-bottom-color: rgba(223, 191, 165, 0.26);
  border-bottom-width: 1px;
  width: 100%;
  margin-top: ${({ lineSpacing }) => lineSpacing + "px"};
`;

type Props = {
  height: number;
  lineSpacing: number;
};

export function HorizontalLines({ height, lineSpacing }: Props) {
  const numberOfLines = Math.ceil(height / lineSpacing);
  return (
    <Container>
      {Array.from({ length: numberOfLines }).map((_, index) => (
        <Line key={index} lineSpacing={lineSpacing} />
      ))}
    </Container>
  );
}
