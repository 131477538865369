import styled from "@emotion/native";
import React from "react";
import { useTranslation } from "react-i18next";
import { Suspect, TeamNumber } from "../../../../services";
import { Avatar } from "../../../../components/Avatar";
import colors from "../../../../ui/constants/colors";
import InputText from "../../../../ui/InputText";
import fonts from "../../../../ui/constants/fonts";

const Container = styled.View`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SuspectContainer = styled.View<{ align: "top" | "bottom" | "center" }>`
  display: flex;
  flex-direction: row;
  align-items: ${({ align }) => {
    switch (align) {
      case "bottom":
        return "flex-end";
      case "top":
        return "flex-start";
      default:
        return "center";
    }
  }};
  gap: 20px;
`;

const InputContainer = styled.View`
  flex: 1;
  height: 100%;
  justify-content: space-between;
`;

const TeamInput = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const TeamText = styled.Text`
  font-family: ${fonts.regular};
  font-size: 16px;
  color: ${colors.textBody};
`;

const TeamPicker = styled.TouchableOpacity`
  flex: 1;
  align-items: center;
`;

const Team = styled.View<{ teamNumber: TeamNumber; isSelected: boolean }>`
  background-color: ${({ teamNumber }) => colors.team[teamNumber]};
  width: 16px;
  height: 16px;
  border-radius: 8px;
  opacity: ${({ isSelected }) => (isSelected ? "1" : "0.4")};
`;

type Props = {
  suspect: Suspect;
  onSuspectChange: (suspect: Suspect) => void;
  align?: "top" | "bottom" | "center";
};

export function InputSuspect({ suspect, onSuspectChange, align = "center" }: Props) {
  const { t } = useTranslation();

  return (
    <Container>
      <SuspectContainer align={align}>
        <Avatar
          avatarId={suspect.avatarId}
          color={colors.team[suspect.teamNumber]}
          size={80}
          onPress={() => {
            let nextAvatarId = suspect.avatarId + 1;
            if (nextAvatarId > 10) nextAvatarId = 1;
            onSuspectChange({ ...suspect, avatarId: nextAvatarId });
          }}
        />
        <InputContainer>
          <TeamInput>
            <TeamText>{t("Team")}</TeamText>
            {[1, 2, 3, 4, 5, 6, 7].map((teamNumber: number) => (
              <TeamPicker
                key={teamNumber}
                onPress={() =>
                  onSuspectChange({ ...suspect, teamNumber: teamNumber as TeamNumber })
                }>
                <Team
                  teamNumber={teamNumber as TeamNumber}
                  isSelected={suspect.teamNumber === teamNumber}
                />
              </TeamPicker>
            ))}
          </TeamInput>
          <InputText
            value={suspect.name || ""}
            placeholder={t("Enter a name")}
            onValueChange={(value) => onSuspectChange({ ...suspect, name: value })}
            testID={`input-suspect-${suspect.id}`}
          />
        </InputContainer>
      </SuspectContainer>
    </Container>
  );
}
