import React, { useRef } from "react";
import styled from "@emotion/native";
import colors from "../../constants/colors";
import { Animated, Pressable, StyleProp, ViewStyle } from "react-native";
import fonts from "../../constants/fonts";

const ButtonContainer = styled.View<{ bgColor: string; pressed: boolean }>`
  background-color: ${(props) => props.bgColor};
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 42px;
  min-width: 200px;
  border-radius: 20px;
  background-color: ${(props) => props.bgColor};
`;

const ButtonText = styled(Animated.Text)<{ textColor: string }>`
  text-transform: uppercase;
  font-family: ${fonts.extraBold};
  color: ${(props) => props.textColor};
`;

type Props = {
  text?: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

export default function ButtonSecondary({ text, onPress, style, testID }: Props): JSX.Element {
  const fontSize = useRef(new Animated.Value(20)).current;
  const bgColor = colors.transparent;
  const textColor = colors.textBody;

  const handlePressIn = () => {
    Animated.timing(fontSize, {
      toValue: 21,
      duration: 150,
      useNativeDriver: false,
    }).start();
  };

  const handlePressOut = () => {
    Animated.timing(fontSize, {
      toValue: 20,
      duration: 150,
      useNativeDriver: false,
    }).start();
  };

  return (
    <Pressable
      onPress={onPress}
      style={{ backgroundColor: colors.transparent }}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      testID={testID}>
      {({ pressed }) => (
        <ButtonContainer pressed={pressed} bgColor={bgColor} style={style}>
          <ButtonText
            textColor={textColor}
            style={{
              fontSize: fontSize,
            }}>
            {text}
          </ButtonText>
        </ButtonContainer>
      )}
    </Pressable>
  );
}
