import styled from "@emotion/native";
import React, { useEffect, useState } from "react";
import Modal from "../../../../ui/Modal";
import { Trans, useTranslation } from "react-i18next";
import Button from "../../../../ui/Button";
import colors from "../../../../ui/constants/colors";
import fonts from "../../../../ui/constants/fonts";

const Content = styled.View`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.Text`
  font-size: 20px;
  color: ${colors.textBody};
  font-family: ${fonts.extraBold};
  text-transform: uppercase;
  text-align: center;
`;

const Description = styled.Text`
  font-size: 16px;
  color: ${colors.textBody};
  font-family: ${fonts.regular};
  padding-bottom: 16px;
  padding-top: 16px;
`;

const ButtonWrapper = styled.View`
  width: auto;
`;

const Bold = styled.Text`
  font-family: ${fonts.extraBold};
`;

type Props = {
  isCaptain: boolean;
  captainName: string | null;
  isVisible: boolean;
  alibiDuration: number;
  onStart: () => void;
  onNext: () => void;
};

export function InstructionsModal({
  isVisible,
  onStart,
  onNext,
  isCaptain,
  captainName,
  alibiDuration,
}: Props) {
  const { t } = useTranslation();
  const [nextDisabled, setNextDisabled] = useState(true);

  useEffect(() => {
    if (!alibiDuration) return;
    setTimeout(() => {
      setNextDisabled(false);
    }, 1000 * alibiDuration);
  }, [alibiDuration]);

  return (
    <Modal isVisible={isVisible} blur>
      <Content>
        <Title>{t("Read your Alibi")}</Title>
        {isCaptain ? (
          <Description>
            {t("You will have 1 minute to read your alibi with your team.")}
            {/* <br />
            <br />
            {t(
              "You are the only one to whom the alibi will be displayed, so gather your team around you or read it out loud."
            )}  */}
            <br />
            <br />
            {t(
              "After that minute, the inspector will ask you questions about it. Your goal will be to answer consistently as a team."
            )}
          </Description>
        ) : (
          <Description>
            {t("You will have 1 minute to read your alibi with your team.")}
            <br />
            <br />
            <Trans
              i18nKey="Only <bold>{{name}}</bold> will have the alibi displayed. Gather around them or ask them to read it aloud."
              components={{ bold: <Bold /> }}
              values={{ name: captainName }}
            />
            <br />
            <br />
            {t(
              "After that minute, the inspector will ask you questions about it. Your goal will be to answer consistently as a team."
            )}
          </Description>
        )}
        <ButtonWrapper>
          {isCaptain ? (
            <Button primary text={t("Start chrono")} onPress={onStart} />
          ) : (
            <Button primary text={t("Next")} onPress={onNext} disabled={nextDisabled} />
          )}
        </ButtonWrapper>
      </Content>
    </Modal>
  );
}
