import styled from "@emotion/native";
import React from "react";
import fonts from "../../../../ui/constants/fonts";
import colors from "../../../../ui/constants/colors";
import { Suspect } from "../../../../services";
import { Avatar } from "../../../../components/Avatar";
import { useTranslation } from "react-i18next";

const Container = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SuspectContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const StatusText = styled.Text<{ status: "waiting" | "arrived" }>`
  font-family: ${fonts.bold};
  font-size: 16px;
  color: ${({ status }) => (status == "waiting" ? colors.statusWarning : colors.statusSuccess)};
`;

const Name = styled.Text`
  font-family: ${fonts.bold};
  font-size: 20px;
  color: ${colors.textBody};
`;

type Props = {
  suspect: Suspect;
  isYou: boolean;
  status: "waiting" | "arrived";
};

export function SuspectWaitingLine({ suspect, status, isYou }: Props) {
  const { t } = useTranslation();

  return (
    <Container>
      <SuspectContainer>
        <Avatar avatarId={suspect.avatarId} color={colors.team[suspect.teamNumber]} />
        <Name>
          {suspect.name} {isYou ? " (YOU)" : ""}
        </Name>
      </SuspectContainer>
      <StatusText testID={`status-${suspect.name}`} status={status}>
        {status === "arrived" ? t("Arrived!") : t("Waiting")}
      </StatusText>
    </Container>
  );
}
