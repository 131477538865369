import React from "react";
import { Screen } from "../../../ui/Screen";
import { useTranslation } from "react-i18next";
import styled from "@emotion/native";
import Button from "../../../ui/Button";
import { Image } from "react-native";

const Container = styled.View`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Top = styled.View`
  gap: 16px;
  flex: 1;
`;

const Bottom = styled.View``;

const ImageContainer = styled.View`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export function WaitingScreen() {
  const { t } = useTranslation();

  return (
    <Screen>
      <Container>
        <Top>
          <ImageContainer>
            <Image
              resizeMode="contain"
              source={require("../../../../assets/inspectors/inspector-time.png")}
              style={{ width: "100%", height: "100%" }}
            />
          </ImageContainer>
        </Top>
        <Bottom>
          <Button primary loading loadingText={t("Waiting...")} />
        </Bottom>
      </Container>
    </Screen>
  );
}
