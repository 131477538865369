import { Context, ErrorLevel, LoggerProvider } from "../types";

export class StdOutLoggerProvider implements LoggerProvider {
  info(...msg: unknown[]): void {
    console.info(...msg);
  }
  warn(...msg: unknown[]): void {
    console.warn(...msg);
  }
  debug(...msg: unknown[]): void {
    console.debug(...msg);
  }
  error({
    message,
  }: {
    error: unknown;
    serializeError: string;
    errorId: string;
    message: string;
    level: ErrorLevel;
    context: Context;
  }): void {
    console.error(message);
  }
}
