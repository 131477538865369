// import "./services/wdyr";

import App from "./app/App";
import { initializeTranslation, logger } from "./services";
import { registerRootComponent } from "expo";
import { AppRegistry } from "react-native";
import appJson from "../app.json";

logger.init();
initializeTranslation();

AppRegistry.registerComponent(appJson.expo.name, () => App);
registerRootComponent(App);
