import React from "react";
import { Screen } from "../../../ui/Screen";
import { useTranslation } from "react-i18next";
import styled from "@emotion/native";
import { Image, Pressable } from "react-native";
import colors from "../../../ui/constants/colors";
import fonts from "../../../ui/constants/fonts";

const Container = styled(Pressable)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Title = styled.Text`
  font-family: ${fonts.extraBold};
  font-size: 36px;
  color: ${colors.textBody};
`;

const ImageContainer = styled.View`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type Props = {
  onPress?: () => void;
};

export function WrongScreen({ onPress }: Props) {
  const { t } = useTranslation();

  return (
    <Screen bgColor={colors.statusError}>
      <Container onPress={onPress}>
        <ImageContainer>
          <Image
            resizeMode="contain"
            source={require("../../../../assets/inspectors/inspector-error.png")}
            style={{ width: "100%", height: "100%" }}
          />
        </ImageContainer>
        <Title>{t("Not Correct!")}</Title>
      </Container>
    </Screen>
  );
}
