import { SENTRY_DNS, API_URL, WEBSOCKET_URL, ENVIRONMENT, WEB_BASE_URL } from "react-native-dotenv";
import { Platform } from "react-native";

type Environment = {
  isMobile: boolean;
  environment: "dev" | "prod";
  sentryDns: string;
  apiUrl: string;
  websocketUrl: string;
  webBaseUrl?: string;
};

if (!process.env.SENTRY_DNS && !SENTRY_DNS)
  throw new Error("Missing SENTRY_DNS environment variable");

if (!process.env.API_URL && !API_URL) throw new Error("Missing API_URL environment variable");

if (!process.env.WEBSOCKET_URL && !WEBSOCKET_URL)
  throw new Error("Missing WEBSOCKET_URL environment variable");

const isMobile = Platform.OS !== "web";

const env: Environment = {
  isMobile: isMobile,
  environment: (process.env.ENVIRONMENT as "dev" | "prod") || ENVIRONMENT || "dev",
  sentryDns: process.env.SENTRY_DNS || SENTRY_DNS,
  apiUrl: process.env.API_URL || API_URL,
  websocketUrl: process.env.WEBSOCKET_URL || WEBSOCKET_URL,
  webBaseUrl: process.env.WEB_BASE_URL || WEB_BASE_URL,
};

export default Object.freeze(env);
