const darkBlue = {
  300: "#38596D",
  400: "#1B3342",
  500: "#081C27",
};

const grey = {
  0: "#FFFFFF",
  100: "#E5E5E5",
  200: "#CCCCCC",
  300: "#B2B2B2",
  400: "#999999",
  500: "#808080",
  600: "#666666",
  700: "#4D4D4D",
  800: "#333333",
  900: "#1A1A1A",
  1000: "#000000",
};

const green = {
  500: "#2CC632",
};

const orange = {
  500: "#FFA41B",
};

const red = {
  500: "#C62C2C",
};

const amber = {
  500: "#EDB315",
};

const colors = {
  transparent: "transparent",
  // Surfaces
  surfacePrimary: "#EFA535",
  surfaceDisabled: "#74644D",
  surfaceSecondary: darkBlue[400],
  surfaceBackground: darkBlue[500],
  // Texts
  textPrimary: darkBlue[500],
  textBody: grey[0],
  textSubtitle: "#A9B3B8",
  // Borders
  borderPrimary: amber[500],
  borderSecondary: darkBlue[300],
  // Status
  statusSuccess: green[500],
  statusError: red[500],
  statusWarning: orange[500],
  team: {
    1: "#F33C73",
    2: "#3C4EF3",
    3: "#F0F33C",
    4: "#F3943C",
    5: "#3CF3E4",
    6: "#3CF33C",
    7: "#F33CF3",
  },
};

export default colors;
