import { getRequestId } from "../../utils/getRequestId";
import { getSessionId } from "../../utils/getSessionId";
import { getAlibi } from "../api/alibis";
import { AvailableLang } from "../i18n";
import { Alibi, Vote } from "../types";
import { Game } from "./game";
import { EventListener, GameEvent } from "./types";

const MAX_RECONNECT_ATTEMPTS = 5;

export default class GameManager {
  private static instance: GameManager;
  private ws: WebSocket | null = null;
  private reconnectAttempts = 0;

  private gameUpdatedListeners: EventListener<Game | null>[] = [];
  private alibiUpdatedListeners: EventListener<Alibi | null>[] = [];
  private webSocketUrl: string;

  private userToken: string | null = null;
  private game: Game | null = null;
  private alibi: Alibi | null = null;

  private lang: AvailableLang = AvailableLang.en;

  private constructor({ webSocketUrl, userToken }: { webSocketUrl: string; userToken: string }) {
    this.webSocketUrl = webSocketUrl;
    this.userToken = userToken;
  }

  static getInstance({
    webSocketUrl,
    userToken,
  }: {
    webSocketUrl: string;
    userToken: string;
  }): GameManager {
    if (!userToken) {
      throw new Error("User token is required");
    }
    if (!webSocketUrl) {
      throw new Error("WebSocket url is required");
    }
    if (!GameManager.instance) {
      GameManager.instance = new GameManager({ webSocketUrl, userToken });
    }
    return GameManager.instance;
  }

  sendResponse(response: string, suspectId: string, questionId: string) {
    this._sendMessage({
      type: "send-response",
      payload: {
        response,
        suspectId,
        questionId,
      },
    });
  }

  sendVotes(votes: Vote[]) {
    this._sendMessage({
      type: "send-votes",
      payload: {
        votes,
      },
    });
  }

  startGame() {
    this._sendMessage({
      type: "start-game",
      payload: null,
    });
  }

  selectSuspect(suspectId: string) {
    this._sendMessage({
      type: "select-suspect",
      payload: {
        suspectId,
      },
    });
  }

  getActiveGame() {
    return this.game;
  }

  getActiveAlibi() {
    return this.alibi;
  }

  joinGame(gameId: string, lang: AvailableLang): void {
    if (this.game && this.game.id === gameId) {
      console.warn("Game already joined");
      return;
    }
    if (this.game && this.game.id !== gameId) {
      console.warn("Already joined to another game");
      return;
    }
    if (this.ws) {
      console.warn("Already connected to a game");
      throw new Error("Already connected to a game");
    }

    this.lang = lang;
    this.createWebSocketConn(gameId);
  }

  quitGame(): void {
    if (!this.ws) {
      throw new Error("Already disconnected from a game");
    }

    // Removing the game before closing the connection allow to not try to reconnect
    this.game = null;
    this.alibi = null;

    this.ws.close(1000);
    this.ws = null;

    this.gameUpdatedListeners.forEach((listener) => listener(null));
  }

  onGameUpdate(listener: EventListener<Game | null>): void {
    this.gameUpdatedListeners.push(listener);
  }

  onAlibiUpdate(listener: EventListener<Alibi | null>): void {
    this.alibiUpdatedListeners.push(listener);
  }

  removeListener(listenerToRemove: EventListener<Game | null> | EventListener<Alibi | null>): void {
    this.gameUpdatedListeners = this.gameUpdatedListeners.filter(
      (listener) => listener !== listenerToRemove
    );
    this.alibiUpdatedListeners = this.alibiUpdatedListeners.filter(
      (listener) => listener !== listenerToRemove
    );
  }

  removeListeners = (): void => {
    this.gameUpdatedListeners = [];
    this.alibiUpdatedListeners = [];
  };

  private createWebSocketConn = (gameId: string): void => {
    if (!gameId) {
      throw new Error("No game id");
    }
    if (!this.userToken) {
      throw new Error("No user token");
    }
    const sessionId = getSessionId();
    const requestId = getRequestId();

    this.ws = new WebSocket(
      `${this.webSocketUrl}/ws/game?userToken=${this.userToken}&gameId=${gameId}&sessionId=${sessionId}&requestId=${requestId}`
    );

    this.ws.addEventListener("open", () => {
      this.reconnectAttempts = 0;
    });

    this.ws.addEventListener("message", async (event) => {
      const gameEvent: GameEvent = JSON.parse(event.data);
      switch (gameEvent.type) {
        case "game-updated": {
          const alibiId = gameEvent.payload.gameInfo.alibiId;
          const gameStatus = gameEvent.payload.gameInfo.status;
          // We do not want to put the alibi text in the real time objects
          if (gameStatus === "STARTED" && (this.alibi === null || this.alibi.id !== alibiId)) {
            this.alibi = await getAlibi({ alibiId: alibiId, lang: this.lang });
          }
          this.game = new Game(gameEvent.payload, this.alibi);
          this.gameUpdatedListeners.forEach((listener) => listener(this.game));
          break;
        }
        default:
          console.warn(`Unknown event type: ${event.type}`);
      }
    });

    this.ws.addEventListener("error", (event) => {
      console.error("WebSocket Error:", event);
    });

    this.ws.addEventListener("close", (event) => {
      if (this.game === null) return;
      if (this.reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
        this.reconnectAttempts++;
        console.warn(`WebSocket closed. Reconnecting attempt ${this.reconnectAttempts}...`);
        setTimeout(() => this.createWebSocketConn(gameId), 2000 * this.reconnectAttempts);
        return;
      }
      throw new Error(`WebSocket closed after ${MAX_RECONNECT_ATTEMPTS} attempts`);
    });
  };

  private _sendMessage(message: GameEvent): void {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(message));
    }
  }
}
