import styled from "@emotion/native";
import React from "react";
import colors from "../../../../ui/constants/colors";

const Container = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30px;
  height: 30px;
  position: relative;
`;

const Bar = styled.View<{ heightFactor: number; bgColor?: string }>`
  height: ${({ heightFactor }) => heightFactor * 10}px;
  width: 7px;
  background-color: ${({ bgColor }) => bgColor || colors.textBody};
`;

type Level = 1 | 2 | 3;

type Props = {
  level: Level;
};

export function Difficulty({ level }: Props) {
  const levelColors = {
    1: colors.statusSuccess,
    2: colors.statusWarning,
    3: colors.statusError,
  };

  return (
    <Container>
      {[1, 2, 3].map((num) => (
        <Bar
          key={num}
          heightFactor={num}
          bgColor={num <= level ? levelColors[level] : colors.textBody}
        />
      ))}
    </Container>
  );
}
