import React, { useCallback, useEffect, useState } from "react";
import RNRestart from "react-native-restart";
import InputText from "../../../ui/InputText";
import { Screen } from "../../../ui/Screen";
import { useTranslation } from "react-i18next";
import styled from "@emotion/native";
import fonts from "../../../ui/constants/fonts";
import colors from "../../../ui/constants/colors";
import { Avatar } from "../../../components/Avatar";
import Button from "../../../ui/Button";
import { useMe } from "../../../hooks/useMe";
import useGameManager from "../../../hooks/useGameManager";
import { logger } from "../../../services";
import useGameRouter from "../hooks/useGameRouter";
import ErrorMsg from "../../../ui/ErrorMsg";
import environments from "../../../environments";
import { InstructionsModal } from "./components/InstructionsModal";
import { WaitingScreen } from "../components/WaitingScreen";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { CorrectScreen } from "../components/CorrectScreen";
import { WrongScreen } from "../components/WrongScreen";

const Container = styled.View`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Header = styled.View`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  padding-bottom: 48px;
`;

const Title = styled.Text`
  font-size: 24px;
  font-family: ${fonts.extraBold};
  color: ${colors.textBody};
`;

const Question = styled.Text`
  font-size: 20px;
  font-family: ${fonts.semiBold};
  color: ${colors.textBody};
`;

const Top = styled.View`
  gap: 16px;
`;

const Bottom = styled.View``;

export function QuestionScreen() {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const { token, userId } = useMe();
  const { game, sendResponse } = useGameManager({ userToken: token });
  useGameRouter({ game, currentScreen: "Question" });
  const [error, setError] = useState<boolean>(false);
  const [instructionsVisible, setInstructionsVisible] = useState<boolean>(
    (game && game.getCurrentQuestionIndex() === 0) || false
  );
  const [isWaitingVisible, setIsWaitingVisible] = useState<boolean>(false);
  const mySuspect = userId && game?.getSuspectByUserId(userId);
  const [resultIsVisible, setResultIsVisible] = React.useState<null | "UP" | "DOWN">(null);
  const [disabledResult, setDisabledResult] = React.useState<boolean>(false);

  useFocusEffect(
    useCallback(() => {
      setResultIsVisible(null);
      setDisabledResult(false);
      setIsWaitingVisible(false);
      setError(false);
      setValue("");
      return () => {};
    }, [])
  );

  const submit = useCallback(() => {
    if (!game) return;
    if (!userId) return;
    const mySuspectId = game.getSuspectId(userId);
    const currentQuestionId = game.getCurrentQuestion()?.id;
    if (!mySuspectId || !currentQuestionId) {
      logger.error("suspectId or questionId is undefined", {
        message: "suspectId or questionId is undefined",
        file: "QuestionScreen",
        function: "submit",
        extraData: {
          gameId: game.id,
          userId: userId,
          suspectId: mySuspectId,
          questionId: currentQuestionId,
        },
      });
      setError(true);
      return;
    }

    sendResponse(value, mySuspectId, currentQuestionId);
  }, [game, userId, value, sendResponse]);

  useFocusEffect(
    useCallback(() => {
      if (!game) return;
      if (!userId) return;
      const questionIndex = game.getCurrentQuestionIndex();
      const currentQuestionResponses = game.getResponses(questionIndex);
      const mySuspectId = game.getSuspectId(userId);
      if (!mySuspectId) return;

      const myResponse = currentQuestionResponses.find(
        (response) => response.suspectId === mySuspectId
      );

      if (myResponse) {
        setIsWaitingVisible(true);
      }
    }, [game, userId])
  );

  useFocusEffect(
    useCallback(() => {
      const lastQuestionIndex = game && game?.getCurrentQuestionIndex() - 1;
      const myTeamNumber = userId && game?.getSuspectByUserId(userId)?.teamNumber;
      const lastQuestionTeamsResponses =
        lastQuestionIndex !== null && game?.getTeamsResponses(lastQuestionIndex);
      const result =
        myTeamNumber &&
        lastQuestionTeamsResponses &&
        lastQuestionTeamsResponses.find(
          (teamResponses) => teamResponses.teamNumber === myTeamNumber
        )?.result;

      if (result) {
        setResultIsVisible(result);
      }
    }, [game, userId])
  );

  if (isWaitingVisible) return <WaitingScreen />;

  if (!disabledResult && resultIsVisible === "UP") {
    return <CorrectScreen onPress={() => setDisabledResult(true)} />;
  }
  if (!disabledResult && resultIsVisible === "DOWN") {
    return <WrongScreen onPress={() => setDisabledResult(true)} />;
  }

  return (
    <Screen>
      <InstructionsModal
        isVisible={instructionsVisible}
        onStart={() => setInstructionsVisible(false)}
      />
      {error ? (
        <ErrorMsg
          text={t("Server error")}
          retry={() => {
            if (environments.isMobile) {
              RNRestart.Restart();
            } else {
              window.location.reload();
            }
          }}
        />
      ) : (
        <Container>
          <Top>
            <Header>
              {mySuspect && (
                <Avatar
                  size={50}
                  avatarId={mySuspect.avatarId}
                  color={colors.team[mySuspect.teamNumber]}
                />
              )}
              <Title>
                {t("Question N°")}
                {game ? game.getCurrentQuestionIndex() + 1 : 0} /{" "}
                {game ? game.getNumberOfQuestions() : "10"}
              </Title>
            </Header>
            <Question>{game ? game.getCurrentQuestion()?.text : "Loading question..."}</Question>
            <InputText
              value={value}
              numberOfLines={8}
              onValueChange={(_value) => setValue(_value)}
              placeholder={t("Enter your response...")}
            />
          </Top>
          <Bottom>
            <Button
              primary
              text={t("Send response")}
              onPress={submit}
              disabled={!game || !userId || value.length === 0}
            />
          </Bottom>
        </Container>
      )}
    </Screen>
  );
}
