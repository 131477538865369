import React from "react";
import styled from "@emotion/native";
import colors from "../../../../ui/constants/colors";
import fonts from "../../../../ui/constants/fonts";

const Container = styled.View`
  padding: 12px;
  border-radius: 8px;
  background: ${colors.textBody};
  gap: 4px;
`;

const Name = styled.Text`
  font-size: 16px;
  font-family: ${fonts.bold};
`;

const TextContent = styled.Text`
  font-size: 16px;
  font-family: ${fonts.regular};
`;

type Props = {
  text: string;
  suspectName: string;
};

export function Response({ text, suspectName }: Props) {
  return (
    <Container>
      <Name>{suspectName}</Name>
      <TextContent>{text}</TextContent>
    </Container>
  );
}
