import styled from "@emotion/native";
import React from "react";
import Modal from "../../../../ui/Modal";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/Button";
import colors from "../../../../ui/constants/colors";
import fonts from "../../../../ui/constants/fonts";
import { Image } from "react-native";

const Content = styled.View`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.Text`
  font-size: 20px;
  color: ${colors.textBody};
  font-family: ${fonts.extraBold};
  text-transform: uppercase;
  text-align: center;
`;

const Description = styled.Text`
  font-size: 16px;
  color: ${colors.textBody};
  font-family: ${fonts.regular};
  padding-bottom: 16px;
  padding-top: 16px;
`;

const ButtonWrapper = styled.View`
  width: auto;
`;

const InspectorContainer = styled.View`
  position: absolute;
  top: -200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  isVisible: boolean;
  onStart: () => void;
};

export function InstructionsModal({ isVisible, onStart }: Props) {
  const { t } = useTranslation();

  return (
    <Modal isVisible={isVisible} blur>
      <Content>
        <InspectorContainer>
          <Image
            resizeMode="contain"
            source={require("../../../../../assets/inspectors/inspector-base.png")}
            style={{ width: 150, height: 150 }}
          />
        </InspectorContainer>
        <Title>{t("Inspector questions")}</Title>
        <Description>
          {t("Try to match your team's answers without chatting with them.")}
        </Description>
        <ButtonWrapper>
          <Button primary text={t("Start questions")} onPress={onStart} />
        </ButtonWrapper>
      </Content>
    </Modal>
  );
}
