import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getUserToken } from "../services/me/getUserToken";
import { createAccount, getMe } from "../services/api/users";
import { logger } from "../services";

type Me = {
  token: string | null;
  userId: string | null;
  error: boolean;
  loading: boolean;
};

const MeContext = createContext<Me | null>(null);

export const MeProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      //await resetUser();
      setLoading(true);
      const token = await getUserToken();
      if (!token) {
        logger.error(new Error("No token"), {
          message: "Error getting user token",
          file: "useMe",
          function: "init",
        });
        setError(true);
        setLoading(false);
        return;
      }

      let me;
      try {
        me = await getMe(token);
        if (!me) me = await createAccount(token);
      } catch (e) {
        logger.error(e, {
          file: "useMe",
          function: "init",
          message: "Error getting me",
          extraData: {
            me,
          },
        });
      }

      if (me) {
        console.log("userId", me.id);
        setUserId(me.id);
        setToken(token);
      } else {
        setError(true);
      }
      setLoading(false);
    };
    init();
  }, []);

  const value = useMemo(
    () => ({
      token: token,
      userId: userId,
      error: error,
      loading: loading,
    }),
    [token, userId, error, loading]
  );

  return <MeContext.Provider value={value}>{children}</MeContext.Provider>;
};

export const useMe = () => {
  const context = useContext(MeContext);
  if (context === null) {
    throw new Error("useMe must be used within a MeProvider");
  }
  return context;
};
