import environments from "../../environments";
import { AvailableLang } from "../i18n";
import { Alibi, PublicAlibi } from "../types";
import { customFetch } from "./utils/customFetch";

export const getAllPublicAlibis = async ({
  lang,
}: {
  lang: AvailableLang;
}): Promise<PublicAlibi[]> => {
  const url = new URL(`${environments.apiUrl}/alibis`);
  if (lang) {
    url.searchParams.append("lang", lang);
  }
  return await customFetch(url.toString());
};

export const getAlibi = async ({
  alibiId,
  lang,
}: {
  alibiId: string;
  lang: AvailableLang;
}): Promise<Alibi | null> => {
  const url = new URL(`${environments.apiUrl}/alibis/${alibiId}`);
  if (lang) {
    url.searchParams.append("lang", lang);
  }
  return await customFetch(url.toString());
};
