import styled from "@emotion/native";
import React from "react";
import { Avatar } from "../Avatar";
import { Suspect } from "../../services";
import colors from "../../ui/constants/colors";

const AvatarContainer = styled.View<{ index: number }>`
  z-index: ${({ index }) => 10000 + index};
  margin-left: ${({ index }) => (index === 0 ? "0px" : "-25px")};
`;

type Props = {
  suspects: Suspect[] | undefined;
};

export function TeamAvatars({ suspects }: Props) {
  return (
    <>
      {suspects &&
        suspects.map((suspect, index) => {
          return (
            <AvatarContainer key={suspect.id + index} index={index}>
              <Avatar
                size={70}
                color={colors.team[suspect.teamNumber]}
                avatarId={suspect.avatarId}
              />
            </AvatarContainer>
          );
        })}
    </>
  );
}
