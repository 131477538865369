import styled from "@emotion/native";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "react-native";
import colors from "../../ui/constants/colors";
import { WifiSlash } from "phosphor-react-native";
import fonts from "../../ui/constants/fonts";
import NetInfo from "@react-native-community/netinfo";

const Container = styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: ${colors.surfaceBackground};
  height: 100%;
  width: 100%;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  flex: 1;
  width: 100%;
  height: fit-content;
`;

const ImageContainer = styled.View`
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Infos = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const Subtitle = styled.Text`
  flex: 1;
  font-size: 16px;
  font-family: ${fonts.bold};
  color: ${colors.textBody};
  text-align: center;
`;

export function NoConnection() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      setIsVisible(!state.isConnected);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  if (!isVisible) return <></>;

  return (
    <Container>
      <Content>
        <ImageContainer>
          <Image
            resizeMode="contain"
            source={require("../../../assets/inspectors/inspector-error.png")}
            style={{ width: "100%", height: "100%" }}
          />
        </ImageContainer>
        <Infos>
          <WifiSlash size={64} color={colors.textBody} />
          <Subtitle>{t("No internet connection")}</Subtitle>
        </Infos>
      </Content>
    </Container>
  );
}
