import styled from "@emotion/native";
import React from "react";
import { ArrowLeft } from "phosphor-react-native";
import fonts from "../../ui/constants/fonts";
import { useNavigation } from "@react-navigation/native";
import { TouchableOpacity } from "react-native";
import colors from "../../ui/constants/colors";

const Container = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 47px;
  padding: 0px 10px;
`;

const Left = styled.View`
  flex: 1;
`;

const Center = styled.View`
  flex: 8;
  text-align: center;
`;

const Title = styled.Text`
  font-family: ${fonts.extraBold};
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.textBody};
`;

const Right = styled.View`
  flex: 1;
`;

type Props = {
  title?: string;
};

export function HeaderBack({ title }: Props) {
  const navigation = useNavigation();

  return (
    <Container>
      <Left>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <ArrowLeft color="white" weight="bold" />
        </TouchableOpacity>
      </Left>
      <Center>
        <Title>{title}</Title>
      </Center>
      <Right></Right>
    </Container>
  );
}
