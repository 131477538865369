import { logger } from "..";
import * as Crypto from "expo-crypto";
import { getItemSecure, setItemSecure } from "../../utils/secureStore";

let userTokenCache: string | null = null;

export const getUserToken = async (): Promise<string> => {
  if (userTokenCache) return userTokenCache;
  let userToken;
  try {
    userToken = await getItemSecure("userToken");
  } catch (e) {
    logger.error(e, {
      message: "Error getting user token",
      file: "getUserToken",
      function: "getUserToken",
    });
  }
  if (!userToken) {
    const randomUuid = Crypto.randomUUID();
    const randomUuidHashed = await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA256,
      randomUuid
    );
    userToken = `0x${randomUuidHashed}`;

    try {
      await setItemSecure("userToken", userToken);
    } catch (e) {
      logger.error(e, {
        message: "Error setting user token",
        file: "getUserToken",
        function: "getUserToken",
      });
    }
  }
  userTokenCache = userToken;
  return userToken;
};
