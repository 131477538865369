import styled from "@emotion/native";
import React, { useCallback, useEffect } from "react";
import fonts from "../../../../ui/constants/fonts";

const chronoColor = "#BD1313";

const ChronoImage = styled.Image`
  position: absolute;
`;

const Container = styled.View`
  width: 210px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChronoText = styled.Text`
  font-size: 36px;
  letter-spacing: -2px;
  font-family: ${fonts.chrono};
  color: ${chronoColor};
  text-transform: uppercase;
  margin-top: 22px;
`;

type Props = {
  totalTime: number;
  onTimeFinished: () => void;
};

export function Chrono({ totalTime, onTimeFinished }: Props) {
  const [timeLeft, setTimeLeft] = React.useState<null | number>(null);

  const chronoTick = useCallback((_timeLeft: number) => {
    if (_timeLeft < 0) return;
    setTimeLeft(_timeLeft);
    setTimeout(() => {
      chronoTick(_timeLeft - 1);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!totalTime) return;
    chronoTick(totalTime);
  }, [chronoTick, totalTime]);

  useEffect(() => {
    if (timeLeft === 0) {
      onTimeFinished();
    }
  }, [onTimeFinished, timeLeft]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <Container>
      <ChronoImage
        resizeMode="contain"
        source={require("../../../../../assets/imgs/chrono.svg")}
        style={{ width: "100%", height: "100%" }}
      />
      <ChronoText>{timeLeft && formatTime(timeLeft)}</ChronoText>
    </Container>
  );
}
