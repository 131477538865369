export enum Difficulty {
  EASY = 1,
  MEDIUM = 2,
  HARD = 3,
}

export type User = {
  id: string;
  token: string;
};

export type PublicAlibi = {
  id: string;
  name: string;
  difficulty: Difficulty;
  creditCost: number;
  displayOrder: number;
};

export type Alibi = {
  text: string;
  questions: Question[];
} & PublicAlibi;

export type Question = {
  id: string;
  text: string;
  displayOrder: number;
  difficulty: Difficulty;
};

export type GameInfo = {
  id: string;
  alibiId: string;
  joinNumber: number;
  ownerId: string | null;
  status: GameStatus;
};

export type Response = {
  id: string;
  suspectId: string;
  questionId: string;
  text: string;
};

export type GameStatus = "WAITING" | "STARTED" | "FINISHED" | "CANCELLED";

export type TeamNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type Suspect = {
  id: string;
  name: string | null;
  userId: string | null;
  teamNumber: TeamNumber;
  avatarId: number;
};

export type VoteType = "UP" | "DOWN";

export type Vote = {
  id?: string;
  suspectId: string;
  teamNumber: TeamNumber;
  questionId: string;
  voteType: VoteType;
};

export const isSuspect = (obj: unknown): boolean => {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "id" in obj &&
    typeof obj.id === "string" &&
    "name" in obj &&
    typeof obj.name === "string" &&
    "userId" in obj &&
    typeof obj.name === "string" &&
    "teamNumber" in obj &&
    typeof obj.teamNumber === "number" &&
    "avatarId" in obj &&
    typeof obj.avatarId === "number"
  );
};
