import { StatusBar } from "expo-status-bar";
import ErrorBoundary from "./ErrorBoundary";
import { useFonts } from "expo-font";
import { StrictMode } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { NavigationContainer } from "@react-navigation/native";
import { AppStackNavigator } from "./AppStackNavigator";
import { MeProvider } from "../hooks/useMe";
import { NoConnection } from "./NoConnection";

export default function App(): JSX.Element {
  const [fontsLoaded] = useFonts({
    "Nunito Bold": require("../../assets/fonts/Nunito-Bold.ttf"),
    "Nunito ExtraBold": require("../../assets/fonts/Nunito-ExtraBold.ttf"),
    "Nunito Regular": require("../../assets/fonts/Nunito-Regular.ttf"),
    "Nunito SemiBold": require("../../assets/fonts/Nunito-SemiBold.ttf"),
    "SigmarOne Regular": require("../../assets/fonts/SigmarOne-Regular.ttf"),
    "PressStart2P Regular": require("../../assets/fonts/PressStart2P-Regular.ttf"),
    "OdorMeanChey Regular": require("../../assets/fonts/OdorMeanChey-Regular.ttf"),
  });

  if (!fontsLoaded) {
    return <></>;
  }

  return (
    <StrictMode>
      <ErrorBoundary>
        <MeProvider>
          <SafeAreaProvider>
            <NoConnection />
            <NavigationContainer>
              <StatusBar style="light" />
              <AppStackNavigator />
            </NavigationContainer>
          </SafeAreaProvider>
        </MeProvider>
      </ErrorBoundary>
    </StrictMode>
  );
}
