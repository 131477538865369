import styled from "@emotion/native";
import React from "react";
import { Image, ImageSourcePropType } from "react-native";
import { Languages } from "./LanguageModal";
import { AvailableLang } from "../../../services";

const Container = styled.View`
  width: 28px;
  height: 28px;
  border-radius: 28px;
  overflow: hidden;
`;

type Props = {
  lang: string;
};

export function LanguageFlag({ lang }: Props) {
  return (
    <Container>
      <Image
        resizeMode="cover"
        source={
          (
            Languages.find((el) => el.code === lang) as {
              image: ImageSourcePropType;
            }
          ).image
        }
        style={{ width: "100%", height: "100%" }}
      />
    </Container>
  );
}
