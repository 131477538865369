import { Context, ErrorLevel, LoggerProvider } from "../types";
import * as Sentry from "sentry-expo";

const EXTRA_DATA = "Extra data";

export class SentryLoggerProvider implements LoggerProvider {
  private static _isInitialized = false;
  private static sessionId: string;

  constructor({ dns, sessionId }: { dns: string; sessionId: string }) {
    SentryLoggerProvider.init(dns, sessionId);
  }

  static init(dns: string, sessionId: string): void {
    if (this._isInitialized) {
      return;
    }
    this.sessionId = sessionId;
    Sentry.init({
      dsn: dns,
      enableInExpoDevelopment: true,
      debug: false,
      beforeSend(event) {
        if (event.exception) {
          if (!event.exception.values) {
            event.exception.values = [];
          }
          // Override Sentry issue title
          if (event?.contexts?.[EXTRA_DATA]?.message) {
            event.exception.values[0].type = String(event.contexts[EXTRA_DATA].message);
          }
          // Override Sentry issue description
          if (event?.tags?.fileName && event?.tags?.functionName) {
            event.exception.values[0].value = `${String(event.tags.fileName)}/${String(
              event.tags.functionName
            )}()`;
          }
        }
        return event;
      },
    });
    this._isInitialized = true;
  }

  info(msg: string): void {
    Sentry.Native.captureMessage(msg, "info");
  }
  warn(msg: string): void {
    Sentry.Native.captureMessage(msg, "warning");
  }
  debug(msg: string): void {
    Sentry.Native.captureMessage(msg, "debug");
  }

  error({
    error,
    serializeError,
    errorId,
    message,
    level,
    context,
  }: {
    error: unknown;
    serializeError: string;
    errorId: string;
    message: string;
    level: ErrorLevel;
    context: Context;
  }): void {
    Sentry.Native.captureException(error, {
      level: level,
      contexts: {
        [EXTRA_DATA]: {
          message: message,
          error: serializeError,
          ...context.extraData,
        },
      },
      tags: {
        sessionId: SentryLoggerProvider.sessionId,
        errorId: errorId,
        fileName: context.file,
        functionName: context.function,
      },
    });
  }
}
