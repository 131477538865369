import styled from "@emotion/native";
import React from "react";

const Input = styled.TextInput<{ multiline: boolean }>`
  border-radius: 12px;
  background-color: #fffaf6;
  height: ${(props) => !props.multiline && "46px"};
  padding: 12px;
  width: 100%;
  position: relative;
  outline: none;
`;

type Props = {
  value?: string;
  placeholder?: string;
  maxLength?: number;
  numeric?: boolean;
  onValueChange?: (value: string) => void;
  testID?: string;
  numberOfLines?: number;
};

export default function InputText({
  numeric,
  maxLength,
  value,
  placeholder,
  onValueChange,
  testID,
  numberOfLines = 0,
}: Props): JSX.Element {
  return (
    <Input
      multiline={numberOfLines > 0}
      numberOfLines={numberOfLines}
      onChangeText={onValueChange}
      value={value}
      maxLength={maxLength}
      placeholder={placeholder}
      placeholderTextColor="#999"
      keyboardType={numeric ? "numeric" : "default"}
      testID={testID}
    />
  );
}
