import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJson from "./locales/en.json";
import frJson from "./locales/fr.json";
import esJson from "./locales/es.json";
import ptJson from "./locales/pt.json";
import { getItemSecure, setItemSecure } from "../../utils/secureStore";
import { getLocales } from "expo-localization";

export const defaultNS = "translation";

export enum AvailableLang {
  en = "en",
  fr = "fr",
  pt = "pt",
  es = "es",
}

const getDefaultLanguage = (): string => {
  const locales = getLocales();
  for (const local of locales) {
    switch (local.languageCode) {
      case AvailableLang.en:
        return AvailableLang.en;
      case AvailableLang.fr:
        return AvailableLang.fr;
      case AvailableLang.es:
        return AvailableLang.es;
      case AvailableLang.pt:
        return AvailableLang.pt;
    }
  }
  return AvailableLang.en;
};

export function initializeTranslation(): void {
  getItemSecure("lang").then((lang) => {
    if (!lang) {
      lang = getDefaultLanguage();
      setItemSecure("lang", lang);
    }
    i18n
      .use(initReactI18next)
      .init({
        compatibilityJSON: "v3",
        defaultNS,
        fallbackLng: [AvailableLang.en],
        lng: lang,
        resources: {
          en: {
            translation: enJson,
          },
          fr: {
            translation: frJson,
          },
          es: {
            translation: esJson,
          },
          pt: {
            translation: ptJson,
          },
        },
        interpolation: {
          escapeValue: false,
        },
      })
      .catch(() => undefined);
  });
}

export default i18n;
