import { getRequestId } from "../../../utils/getRequestId";
import { getSessionId } from "../../../utils/getSessionId";

export interface FetchOptions {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  headers?: Record<string, string>;
  body?: BodyInit | null;
}

export const customFetch = async <T>(url: string, options?: FetchOptions): Promise<T> => {
  const sessionId = getSessionId();
  const requestId = getRequestId();

  const mergedOptions = {
    ...options,
    headers: {
      ...options?.headers,
      "X-Session-ID": sessionId,
      "X-Request-ID": requestId,
    },
  };

  try {
    const response = await fetch(url, mergedOptions);
    if (!response.ok) {
      throw new Error(`Network response error with status ${response.status}`);
    }
    return response.json();
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(`${err.message} on requestId ${requestId}`);
    }
    throw new Error(`Network response error on requestId ${requestId}`);
  }
};
