import SHA3 from "sha3";
import { Context, ErrorLevel, LoggerProvider } from "./types";

export class Logger {
  private _loggerProviders: LoggerProvider[];

  constructor({ loggerProviders }: { loggerProviders: LoggerProvider[] }) {
    this._loggerProviders = loggerProviders;
  }

  init(): void {
    console.info("Logger initialized");
  }

  info(message: string, context: Context): void {
    this._callLogProviders("info", message, context);
  }

  debug(message: string, context: Context): void {
    this._callLogProviders("debug", message, context);
  }

  warn(message: string, context: Context): void {
    this._callLogProviders("warn", message, context);
  }

  private _callLogProviders(level: "info" | "debug" | "warn", message: string, context: Context) {
    const formattedMessage = formatMessage(context.file, context.function, message);
    for (const provider of this._loggerProviders) {
      switch (level) {
        case "info":
          provider.info(formattedMessage!, context?.extraData);
          break;
        case "debug":
          provider.debug(formattedMessage!, context?.extraData);
          break;
        case "warn":
          provider.warn(formattedMessage!, context?.extraData);
          break;
      }
    }
  }

  error(error: unknown, context: Context & { message: string; level?: ErrorLevel }): string {
    const hash = new SHA3(256);
    const contextHash = hash.update(context.file + context.function).digest("hex");
    const errorId = `${Date.now()}-${contextHash.slice(-8)}`;
    for (const provider of this._loggerProviders) {
      provider.error({
        error,
        context,
        message: context.message,
        serializeError: serializeError(error),
        errorId,
        level: context.level || "error",
      });
    }
    return errorId;
  }
}

export const serializeError = (error: unknown): string =>
  JSON.stringify(error, Object.getOwnPropertyNames(error));

export const formatMessage = (fileName: string, functionName: string, message: string): string => {
  const t = new Date();
  return `${t.getHours()}:${t.getMinutes()}:${t.getSeconds()}:${t.getMilliseconds()}::${fileName}#${functionName}:${message}`;
};
