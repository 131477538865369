import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import environments from "../environments";

const IS_MOBILE = environments.isMobile;

// SecureStore is not available on web

export const getItemSecure = async (key: string) => {
  if (IS_MOBILE) return await SecureStore.getItemAsync(key);
  return await AsyncStorage.getItem(key);
};

export const setItemSecure = async (key: string, value: string) => {
  if (IS_MOBILE) {
    await SecureStore.setItemAsync(key, value);
    return;
  }
  await AsyncStorage.setItem(key, value);
};
