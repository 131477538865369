import React, { ErrorInfo, ReactNode } from "react";
import RNRestart from "react-native-restart";
import { logger } from "../services/logger";
import styled from "@emotion/native";
import colors from "../ui/constants/colors";
import ErrorMsg from "../ui/ErrorMsg";
import { useTranslation } from "react-i18next";
import environments from "../environments";

const Container = styled.View`
  background-color: ${colors.surfaceBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Content = styled.View`
  min-width: 300px;
`;

interface ErrorBoundaryState {
  hasError: boolean;
}

type Props = { children: ReactNode };

class ErrorBoundary extends React.Component<Props, ErrorBoundaryState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const errorBoundaryError = new Error(error.message);
    errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
    errorBoundaryError.stack = errorInfo.componentStack;
    error.cause = errorBoundaryError;

    logger.error(error, {
      message: "React ErrorBoundary",
      file: "ErrorBoundary.tsx",
      function: "componentDidCatch",
      level: "fatal",
    });
  }

  resetError = () => {
    this.setState({ hasError: false });
  };

  render(): ReactNode {
    if (this.state.hasError) {
      return <ErrorScreen />;
    }
    return this.props.children;
  }
}

function ErrorScreen(): JSX.Element {
  const { t } = useTranslation();

  const restart = () => {
    if (environments.isMobile) {
      RNRestart.Restart();
    } else {
      window.location.reload();
    }
  };

  return (
    <Container>
      <Content>
        <ErrorMsg text={t("Oops! Something crashed.")} retry={() => restart()} />
      </Content>
    </Container>
  );
}

export default ErrorBoundary;
