import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import ButtonPrimary from "./variants/ButtonPrimary";
import ButtonSecondary from "./variants/ButtonSecondary";
import ButtonLoading from "./variants/ButtonLoading";

type Props = {
  text?: string;
  loading?: boolean;
  disabled?: boolean;
  loadingText?: string;
  primary?: boolean;
  secondary?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

export default function Button({
  text,
  disabled,
  loading,
  loadingText,
  primary,
  secondary,
  onPress,
  style,
  testID,
}: Props): JSX.Element {
  if (loading)
    return (
      <ButtonLoading loadingText={loadingText} onPress={onPress} style={style} testID={testID} />
    );
  if (primary)
    return (
      <ButtonPrimary
        text={text}
        onPress={onPress}
        style={style}
        disabled={disabled}
        testID={testID}
      />
    );
  if (secondary)
    return <ButtonSecondary text={text} onPress={onPress} style={style} testID={testID} />;
  return <></>;
}
