const fonts = {
  logo: "SigmarOne Regular",
  bold: "Nunito Bold",
  semiBold: "Nunito SemiBold",
  extraBold: "Nunito ExtraBold",
  regular: "Nunito Regular",
  chrono: "PressStart2P Regular",
  positions: "OdorMeanChey Regular",
};

export default fonts;
