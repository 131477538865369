import styled from "@emotion/native";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Image } from "react-native";
import colors from "../../ui/constants/colors";
import Button from "../../ui/Button";
import fonts from "../../ui/constants/fonts";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { AppStackParamList } from "../AppStackNavigator";
import JoinGameModal from "./components/JoinGameModal";
import { useMe } from "../../hooks/useMe";
import { Screen } from "../../ui/Screen";
import { YesNoModal } from "../../components/YesNoModal";
import { getMyActiveGameInfo, quitGame as quitGameAPI } from "../../services/api/games";
import { InfoModal } from "../../components/InfoModal";
import { AvailableLang, GameInfo } from "../../services";
import LanguageModal from "./components/LanguageModal";
import { LanguageFlag } from "./components/LanguageFlag";
import useGameManager from "../../hooks/useGameManager";
import { setItemSecure } from "../../utils/secureStore";

const Container = styled.View`
  background-color: ${colors.surfaceBackground};
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 16px;
  position: relative;
`;

const Header = styled.View`
  display: flex;
  align-items: flex-end;
  width: 100%;
`;

const LanguageSelection = styled.TouchableOpacity`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background-color: ${colors.surfaceSecondary};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Center = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 16px 0px;
`;

const ImageContainer = styled.View`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.Text`
  font-family: ${fonts.logo};
  color: ${colors.textBody};
  font-size: 64px;
`;

const Subtitle = styled.Text`
  font-family: ${fonts.semiBold};
  color: ${colors.textBody};
  font-size: 20px;
  max-width: 310px;
  text-align: center;
`;

const Bold = styled.Text`
  font-family: ${fonts.extraBold};
`;

const Bottom = styled.View`
  display: flex;
  gap: 12px;
  padding-top: 24px;
`;

type Props = NativeStackScreenProps<AppStackParamList, "Home">;

export function HomeScreen({ navigation, route: { params } }: Props) {
  const { t, i18n } = useTranslation();
  const { token, userId } = useMe();
  const { quitGame } = useGameManager({ userToken: token });

  const [isLanguageVisible, setIsLanguageVisible] = React.useState(false);
  const [isJoinGameVisible, setIsJoinGameVisible] = React.useState(false);

  /************************************************************/
  /****************** ALREADY IN A GAME ***********************/
  /************************************************************/

  const [isAlreadyInGameVisible, setIsAlreadyInGameVisible] = React.useState(false);
  const [activeGameInfo, setActiveGameInfo] = useState<GameInfo | null>(null);

  // Check if the user is already in a game
  useEffect(() => {
    if (!token) return;
    if (!navigation) return;
    const getActiveGame = async () => {
      const gameInfo = await getMyActiveGameInfo();
      if (gameInfo) {
        setActiveGameInfo(gameInfo);
        setIsAlreadyInGameVisible(true);
      }
    };
    getActiveGame();
  }, [token, navigation]);

  /************************************************************/
  /***************** GAME HAS BEEN CANCELLED ******************/
  /************************************************************/

  const [isGameCancelledVisible, setIsGameCancelledVisible] = React.useState(false);

  useEffect(() => {
    if (!params) return;
    if (params.info === "GAME_CANCELLED") {
      quitGame();
      setIsGameCancelledVisible(true);
    }
  }, [params, quitGame]);

  /************************************************************/
  /************************************************************/
  /************************************************************/

  return (
    <Screen>
      <InfoModal
        isVisible={isGameCancelledVisible}
        title={t("Your game has been cancelled")}
        text={t(
          "The owner of your game cancelled it. You can create a new game or join another one."
        )}
        onClose={() => setIsGameCancelledVisible(false)}
      />

      <YesNoModal
        testID={"continue-game-modal"}
        title={t("You are in a game")}
        text={
          userId && activeGameInfo && userId === activeGameInfo?.ownerId
            ? t("If you cancel the Game, all game players will be ejected of the game.")
            : t("If you quit the Game, other player will have to wait a new player to continue.")
        }
        yesText={t("Continue")}
        yes={() => {
          setIsAlreadyInGameVisible(false);
          if (activeGameInfo) {
            navigation.navigate("WaitingRoom", {
              gameId: activeGameInfo.id,
            });
          }
        }}
        noText={
          userId && activeGameInfo && userId === activeGameInfo?.ownerId
            ? t("Cancel the game")
            : t("Quit the game")
        }
        no={() => {
          setIsAlreadyInGameVisible(false);
          if (activeGameInfo) {
            quitGameAPI({ gameId: activeGameInfo.id });
            setActiveGameInfo(null);
          }
        }}
        isVisible={isAlreadyInGameVisible}
      />

      <JoinGameModal
        isVisible={isJoinGameVisible}
        onGameFound={(gameId) => {
          setIsJoinGameVisible(false);
          navigation.navigate("WaitingRoom", {
            gameId: gameId,
          });
        }}
        onClose={() => setIsJoinGameVisible(false)}
      />

      <LanguageModal
        isVisible={isLanguageVisible}
        languageSelected={i18n.language as AvailableLang}
        onLanguageSelected={async (lang) => {
          i18n.changeLanguage(lang);
          setItemSecure("lang", lang);
          setIsLanguageVisible(false);
        }}
        onClose={() => {
          setIsLanguageVisible(false);
        }}
      />

      <Container>
        <Header>
          <LanguageSelection onPress={() => setIsLanguageVisible(true)}>
            <LanguageFlag lang={i18n.language} />
          </LanguageSelection>
        </Header>
        <Center>
          <ImageContainer>
            <Image
              resizeMode="contain"
              source={require("../../../assets/inspectors/inspector-base.png")}
              style={{ width: "100%", height: "100%" }}
            />
          </ImageContainer>
          <Title>
            <Logo testID={"title"}>Alibi</Logo>
            <Subtitle>
              <Trans t={t}>Answer as many questions as possible with your team</Trans>
            </Subtitle>
          </Title>
        </Center>
        <Bottom>
          <Button
            testID={"home-create-game-button"}
            primary
            text={t("Create a game")}
            onPress={() => navigation.navigate("SelectAlibi")}
          />
          <Button
            testID={"home-join-game-button"}
            secondary
            text={t("Join a game")}
            onPress={() => setIsJoinGameVisible(true)}
          />
        </Bottom>
      </Container>
    </Screen>
  );
}
