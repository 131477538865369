import styled from "@emotion/native";
import React from "react";
import fonts from "../../../../ui/constants/fonts";
import colors from "../../../../ui/constants/colors";
import { Difficulty } from "./Difficulty";
import { PublicAlibi } from "../../../../services";

const Container = styled.TouchableOpacity`
  background-color: ${colors.surfaceSecondary};
  height: 82px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 0px 16px;
`;

const AlibiImg = styled.View`
  width: 60px;
  height: 50px;
  background-color: ${colors.surfaceBackground};
  border-radius: 8px;
  margin-right: 16px;
`;

const AlibiInfos = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left: 16px;
`;

const AlibiText = styled.Text`
  font-family: ${fonts.bold};
  font-size: 20px;
  color: ${colors.textBody};
`;

type Props = {
  alibi: PublicAlibi;
  onPress: () => void;
};

export function AlibiCard({ alibi, onPress }: Props) {
  return (
    <Container key={alibi.id} onPress={onPress} testID={"select-alibi-" + alibi.id}>
      {/* <AlibiImg></AlibiImg> */}
      <AlibiInfos>
        <AlibiText>{alibi.name}</AlibiText>
        <Difficulty level={alibi.difficulty} />
      </AlibiInfos>
    </Container>
  );
}
