import styled from "@emotion/native";
import React from "react";
import colors from "../constants/colors";
import fonts from "../constants/fonts";
import { useTranslation } from "react-i18next";
import { ArrowCounterClockwise } from "phosphor-react-native";
import { ActivityIndicator } from "react-native";

const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
`;

const Content = styled.TouchableOpacity`
  max-width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorTitle = styled.Text`
  font-size: 16px;
  font-family: ${fonts.bold};
  color: ${colors.textBody};
  text-align: center;
`;

const Refresh = styled.View`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorSubtitle = styled.Text`
  font-size: 16px;
  font-family: ${fonts.regular};
  color: ${colors.textBody};
  text-align: center;
`;

const Refreshing = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

type Props = {
  text: string;
  retry?: () => void;
};

export default function ErrorMsg({ text, retry }: Props): JSX.Element {
  const { t } = useTranslation();
  const [refreshing, setRefreshing] = React.useState<boolean>(false);

  const refresh = () => {
    setRefreshing(true);
    retry && retry();
    setTimeout(() => {
      setRefreshing(false);
    }, 4000);
  };

  return (
    <Container>
      {refreshing ? (
        <Content>
          <Refreshing>
            <ActivityIndicator color={colors.textBody} />
            <ErrorTitle style={{ marginLeft: 12 }}>{t("Refreshing...")}</ErrorTitle>
          </Refreshing>
        </Content>
      ) : (
        <Content onPress={() => refresh()} disabled={!retry}>
          <ErrorTitle style={{ marginBottom: 8 }}>{text}</ErrorTitle>
          <ErrorSubtitle>{t("Please refresh the app")}</ErrorSubtitle>
          {retry && (
            <Refresh>
              <ArrowCounterClockwise size={18} color={colors.textBody} weight="fill" />
            </Refresh>
          )}
        </Content>
      )}
    </Container>
  );
}
